import React from "react";
import AppHeader from "../navbar/AppHeader";
import Footer from "../Footer/Footer";
import Qoute from "../Qoute/Qoute";
import { Card, Table } from "reactstrap";
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import pie_chart from "../../../landing/assets/images/Pie-chart.png";

const Today = () => {
  const data = {
    series: [
      {
        data: [21, 22, 10, 28, 16],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      yaxis: {
         labels: {
          formatter: function(val, index) {
            return "$" + val.toFixed(2);
          }
         }
      },
      // colors: colors,
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      
      xaxis: {
        categories: [
          ["Savings"],
          ["Investments"],
          ["Retirements"],
          ["H.S.A"],
          ["Property"],
        ],
        labels: {
          style: {
            // colors: colors,
            fontSize: "10px",
          },
        },
      },
    
    },
  };
  return (
    <>
      <div className="pb-5">
        <AppHeader />
      </div>
      <section>
        <div className="prog">
          <div className="finance-wealth-text">
            One Place For All Your <br />
            Finance & Wealth
          </div>

          {/* First Container*/}
          <section className="disposable">
            <div className="container">
              <div className="row ">
                <div className="col-md-6">
                  <div className="content-section">
                    <p className="font-weight-bold source-txt">
                      Know Your Source of Income
                    </p>
                    <h2 className="font-weight-bold">
                      My Disposable <br />
                      Income Focus
                    </h2>
                    {/* <p className="budget-paragraph">
                      There are many variations of passages of Lorem Ipsum
                      available majority have suffered alteration in some form,
                      by injected humour, or randomized.
                    </p> */}
                    <Link to="/signup">
                      <button>Maximize Tax Savings</button>
                    </Link>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="all-bars-responsive">
                    <Card className="py-5 px-3">
                      <div className="d-flex justify-content-between">
                        <span className="total-income">Total Income</span>
                        <span className="right-300k ">$300k</span>
                      </div>
                      <div className=" w3-round-large bar-color mb-3">
                        <div className="  w3-round-xlarge " id="green-bar"></div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <span className="blue-bars-text">
                          Self-employed retirement plans
                        </span>
                        <span className="right-20k">-$20k</span>
                      </div>
                      <div className=" w3-round-large blue-bar-adjustment mb-3">
                        <div className="  w3-round-xlarge " id="blue-bar"></div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <span className="blue-bars-text">IRA, 401(k)</span>
                        <span className="right-13k">-$13k</span>
                      </div>

                      <div className=" w3-round-large blue-bar-adjustment mb-3">
                        <div className="  w3-round-xlarge " id="ira-blue-bar"></div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <span className="blue-bars-text">H.S.A account</span>
                        <span className="right-36k">-$3.6k</span>
                      </div>

                      <div className=" w3-round-large blue-bar-adjustment mb-3">
                        <div className="  w3-round-xlarge " id="hsa-blue-bar"></div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <span className="agi-text">AGI</span>
                        <span className="right-263k">$263.4k</span>
                      </div>

                      <div className=" w3-round-large total-taxes-bar mb-3">
                        <div
                          className="  w3-round-xlarge "
                          id="green-bar-half"
                        ></div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <span className="tax-rate-text">Tax Rate</span>
                        <span className="right-30-per-second">30%</span>
                      </div>

                      <div className=" w3-round-large total-taxes-bar mb-3">
                        <div
                          className="  w3-round-xlarge "
                          id="total-tax-bar"
                        ></div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <span className="tax-rate-text">Total Taxes</span>
                        <span className="right-30-per-second">30%</span>
                      </div>

                      <div className=" w3-round-large total-taxes-bar mb-3">
                        <div
                          className="  w3-round-xlarge "
                          id="total-tax-bar"
                        ></div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <span className="disposable-text">Disposable Income</span>
                        <span className="right-185k">$185k</span>
                      </div>

                      <div className=" w3-round-large total-taxes-bar mb-3">
                        <div
                          className="  w3-round-xlarge "
                          id="disposable-tax-bar"
                        ></div>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
      <section className="shaded-section py-4 mt-5">
        <section className="budget py-5">
          <div className="container py-5">
            <div className="row mt-5">
              <div className="col-md-6 d-flex">
                <Card className="py-3 px-3">
                  <p className="text-center font-weight-bold">
                    Revenue distribution
                  </p>
                  <img src={pie_chart} alt="pie chart" className="mx-auto" />
                  <div className="conatiner px-5">
                    <ul className="px-5">
                      <li>
                        <div className="d-flex justify-content-between">
                          <p className="expense-ratio">Expense Ratio</p>
                          <span className="mt-2">70%</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex justify-content-between">
                          <p className="investment-ratio">Investment Ratio</p>
                          <span className="mt-2">10%</span>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex justify-content-between">
                          <p className="saving-ratio">Saving Ratio</p>
                          <span className="mt-2">20%</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Card>
              </div>
              <div className="col-md-6 py-5">
                <div className="budget-insight">
                  <p className="font-weight-bold spending-txt">
                    Know Your Spending & Investment
                  </p>
                  <h2 className="font-weight-bold">
                    My Budget <br /> Insight
                  </h2>
                  {/* <p className="budget-paragraph">
                    There are many variations of passages of Lorem Ipsum
                    available majority have suffered alteration in some form, by
                    injected humour, or randomized.
                  </p> */}
                  <Link to="/signup">
                    <button>Control My Budget</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="net-worth py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="net-worth-insight">
                  <p className="font-weight-bold spending-txt">
                    Know Your Standing
                  </p>
                  <h2 className="font-weight-bold">
                    My Total Net <br /> Worth Today
                  </h2>
                  {/* <p className="net-worth-paragraph">
                    There are many variations of passages of Lorem Ipsum
                    available majority have suffered alteration in some form, by
                    injected humour, or randomized.
                  </p> */}
                  <Link to="/signup">
                    <button>Know My Net Worth</button>
                  </Link>
                </div>
              </div>
              <div className="col-md-6">
                <div className="chart-table">
                  <Card className="chart px-3 py-2">
                    <h4 className="text-center font-weight-bold">Net Worth</h4>
                    <Chart
                      className=""
                      options={data.options}
                      series={data.series}
                      type="bar"
                      height={350}
                    />
                  </Card>
                  <Card className="px-3 py-3">
                    <Table>
                      <thead>
                        <tr>
                          <th>Total Networth</th>
                          <th>$750,000</th>
                          <th>100%</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Savings</td>
                          <td>$50,000</td>
                          <td>7%</td>
                        </tr>
                        <tr>
                          <td>Retirement</td>
                          <td>$100,000</td>
                          <td>13%</td>
                        </tr>
                        <tr>
                          <td>Investments</td>
                          <td>$30,000</td>
                          <td>40%</td>
                        </tr>
                        <tr>
                          <td>H.S.A account</td>
                          <td>$100,000</td>
                          <td>7%</td>
                        </tr>
                        <tr>
                          <td>Savings</td>
                          <td>$50,000</td>
                          <td>33%</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <Qoute />
      <Footer />
    </>
  );
};

export default Today;
