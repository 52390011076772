import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotificationAlert from "react-notification-alert";
import DataTable from "react-data-table-component";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  ModalFooter,
} from "reactstrap";
import {
  userList,
  deleteUser,
  unDeleteUser,
  updateUser,
} from "../actions/userActions";
import {
  getDisposableIncomeByEmail,
  createDisposable,
  updateDisposable
} from "../actions/disposableActions";

function Clients() {
  const notificationAlert = React.useRef();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [incomeModal, setIncomeModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [userData, setUserData] = useState({});
  const [searchUsers, setSearchUsers] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const currentYear = new Date().getFullYear();
  const lastYear = new Date().getFullYear()-1;
  const [incomeData, setIncomeData] = useState({});
  const [userEmail, setUserEmail] = useState("");

  const list = useSelector((state) => state.usersList);
  const { users } = list;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const disposableRes = useSelector((state) => state.disposableIncomeResByEmail);
  const { disposableIncomeResponses } = disposableRes;

  var formOptions = {
    place: "tr",
    icon: "nc-icon nc-bell-55",
    autoDismiss: 4,
  };

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(userList());
    }
  }, [userInfo, dispatch]);

  useEffect(() => {
    if (users) {
      let arr = [];
      users.map((el) => {
        if (!el.isAdmin && !el.isPM) {
          arr.push(el);
        }
      });
      setUsersList(arr);
      setSearchUsers(arr);
    }
  }, [users]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setModal(false);
    let userUpdatedData = {
      fname: userData.fname,
      lname: userData.lname,
      email: userData.email,
      password: userData.password,
      DOB: userData.DOB,
      phone: userData.phone,
      isAdmin: userData.isAdmin ? true : false,
      isPM: userData.isPM ? true : false,
    };
    const data = await dispatch(updateUser(userUpdatedData, userData.id));
    if (data) {
      formOptions.type = "success";
      formOptions.message = data.message;
      notificationAlert.current.notificationAlert(formOptions);
    } else {
      formOptions.type = "danger";
      formOptions.message = data.message;
      notificationAlert.current.notificationAlert(formOptions);
    }
  };

  const handleDelete = (e) => {
    if (e.deletedAt) {
      dispatch(unDeleteUser(e.id));
    } else {
      dispatch(deleteUser(e.id));
    }
  };

  const handleSearch = (e) => {
    var value = e.target.value.toLowerCase();
    var arr = [];
    usersList.map((el) => {
      let fname = el.fname.toLowerCase();
      let lname = el.lname.toLowerCase();
      let email = el.email.toLowerCase();
      if (
        email.includes(value) ||
        fname.includes(value) ||
        lname.includes(value)
      ) {
        arr.push(el);
        setSearchUsers(arr);
      }
    });
  };

  const handleDisposableIncomeByEmail = (email) => {
    if (email) {
      setUserEmail(email);
      dispatch(getDisposableIncomeByEmail(email));
    }
  };

  useEffect(() => {
    if (disposableIncomeResponses) {
      setIsEdit(true);
      setIncomeData(disposableIncomeResponses);
    } else {
      setIsEdit(false);
      setIncomeData({});
    }
  }, [disposableIncomeResponses]);

  const handleDisposableUpdate = async (e) => {
    e.preventDefault();
    if (Object.keys(incomeData).length !== 0) {
      let disposableData = {
        ...incomeData,
        email: userEmail,
      };
      if(isEdit) {
        const data = await dispatch(updateDisposable(disposableData));
        if (data) {
          formOptions.type = "success";
          formOptions.message = data.message;
          notificationAlert.current.notificationAlert(formOptions);
        } else {
          formOptions.type = "danger";
          formOptions.message = data.message;
          notificationAlert.current.notificationAlert(formOptions);
        }
      } else {
        const data = await dispatch(createDisposable(disposableData));
        if (data) {
          formOptions.type = "success";
          formOptions.message = data.message;
          notificationAlert.current.notificationAlert(formOptions);
        } else {
          formOptions.type = "danger";
          formOptions.message = data.message;
          notificationAlert.current.notificationAlert(formOptions);
        }
      }
      setIncomeModal(false);
    } else {
      formOptions.type = "danger";
      formOptions.message = "Fields can not be Empty!";
      notificationAlert.current.notificationAlert(formOptions);
    }
  }

  const columns = [
    {
      cell: (row) => (
        <>
          <Button
            size="sm"
            onClick={() => {
              handleDisposableIncomeByEmail(row.email);
              setIncomeModal(true);
            }}
            className="btn-primary"
            id={row.email}
          >
            Income
          </Button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row) => (
        <>
          <Button
            size="sm"
            onClick={() => {
              setUserData(row);
              setModal(true);
            }}
            className="btn-secondary"
            id={row.id}
          >
            Edit
          </Button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Name",
      selector: (row) => row.fname + " " + row.lname,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "DOB",
      selector: (row) => row.DOB,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
    },
    {
      cell: (row) => (
        <>
          <Button
            size="sm"
            onClick={() => handleDelete(row)}
            className={row.deletedAt ? "btn-success" : "btn-danger"}
            id={row.id}
          >
            {row.deletedAt ? "Undo Delete" : "Delete"}
          </Button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <>
      <div className="content">
        <NotificationAlert ref={notificationAlert} />

        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Col md="12">
                  <Card className="card-plain">
                    <CardHeader>
                      <div className="d-flex justify-content-between">
                        <CardTitle tag="h4">Clients</CardTitle>

                        <InputGroup className="no-border w-50 p-0">
                          <Input
                            placeholder="Search..."
                            onChange={handleSearch}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>
                              <i className="nc-icon nc-zoom-split" />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </div>
                      <p className="card-category">
                        Here is list of All Clients
                      </p>
                    </CardHeader>
                    <CardBody>
                      <DataTable
                        columns={columns}
                        data={searchUsers}
                        pagination
                        highlightOnHover
                      />
                    </CardBody>
                  </Card>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal isOpen={modal} centered>
          <Form onSubmit={handleUpdate}>
            <ModalHeader charCode="X">Edit User</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="exampleName">First Name</Label>
                <Input
                  type="text"
                  name="fname"
                  defaultValue={userData.fname}
                  onChange={(e) =>
                    setUserData((prev) => ({
                      ...prev,
                      fname: e.target.value,
                    }))
                  }
                  placeholder="First Name"
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleLName">Last Name</Label>
                <Input
                  type="text"
                  name="lname"
                  defaultValue={userData.lname}
                  onChange={(e) =>
                    setUserData((prev) => ({
                      ...prev,
                      lname: e.target.value,
                    }))
                  }
                  placeholder="Last Name"
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleEmail">Email</Label>
                <Input
                  type="email"
                  name="email"
                  disabled
                  defaultValue={userData.email}
                  placeholder="Email"
                />
              </FormGroup>
              <FormGroup>
                <Label for="examplePassword">Password</Label>
                <Input
                  type="password"
                  name="password"
                  placeholder="password"
                  defaultValue={userData.password}
                  onChange={(e) =>
                    setUserData((prev) => ({
                      ...prev,
                      password: e.target.value,
                    }))
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Phone</Label>
                <Input
                  type="text"
                  name="phone"
                  defaultValue={userData.phone}
                  onChange={(e) =>
                    setUserData((prev) => ({
                      ...prev,
                      phone: e.target.value,
                    }))
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Date of Birth</Label>
                <Input
                  type="date"
                  name="DOB"
                  defaultValue={userData.DOB}
                  onChange={(e) =>
                    setUserData((prev) => ({
                      ...prev,
                      DOB: e.target.value,
                    }))
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleSelect">Type</Label>
                <Input
                  type="select"
                  name="select"
                  disabled
                  // onChange={(e) =>
                  //   setUserData((prev) => ({
                  //     ...prev,
                  //     isAdmin: e.target.value === "admin" ? true : false,
                  //     isPM: e.target.value === "pm" ? true : false,
                  //   }))
                  // }
                >
                  <option value={"user"}>User</option>
                  <option value={"pm"}>Portal Manager</option>
                  <option value={"admin"}>Admin</option>
                </Input>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => setModal(false)}>
                Cancel
              </Button>{" "}
              &nbsp;
              <Button color="primary" type="submit">
                Update
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
        <Modal isOpen={incomeModal} centered size="lg">
          <Form onSubmit={handleDisposableUpdate}>
            <ModalHeader charCode="X"> {isEdit ? "Edit Disposable Income" : "Add Disposable Income"} </ModalHeader>
            <ModalBody>
              <Row>
                <Col lg="4"></Col>
                <Col lg="4" className="text-center">
                  <Label for="lastYear" className="yearCSS">
                    Last Year
                  </Label>
                </Col>
                <Col lg="4" className="text-center">
                  <Label for="lastYear" className="yearCSS">
                    Current Year
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col lg="4"></Col>
                <Col lg="4" className="text-center">
                  <Label for="lastYearValue" className="yeraValueCSS">
                    {lastYear}
                  </Label>
                </Col>
                <Col lg="4" className="text-center">
                  <Label for="currentYearValue" className="yeraValueCSS">
                    {currentYear}
                  </Label>
                </Col>
              </Row>
              <Row className="mt-10">
                <Col lg="4">
                  <Label for="totalIncome">Total Income</Label>
                </Col>
                <Col lg="4" className="text-center">
                  <Input
                    type="number"
                    name="totalIncomeLY"
                    defaultValue={incomeData.totalIncomeLY}
                    onChange={(e) =>
                      setIncomeData((prev) => ({
                        ...prev,
                        totalIncomeLY: e.target.value,
                      }))
                    }
                  />
                </Col>
                <Col lg="4" className="text-center">
                  <Input
                    type="number"
                    name="totalIncomeCY"
                    defaultValue={incomeData.totalIncomeCY}
                    onChange={(e) =>
                      setIncomeData((prev) => ({
                        ...prev,
                        totalIncomeCY: e.target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
              <Row className="mt-10">
                <Col lg="4">
                  <Label for="totalIncome">Self-employed retirement plans </Label>
                </Col>
                <Col lg="4" className="text-center">
                  <Input
                    type="number"
                    name="retirementLY"
                    defaultValue={incomeData.retirementLY}
                    onChange={(e) =>
                      setIncomeData((prev) => ({
                        ...prev,
                        retirementLY: e.target.value,
                      }))
                    }
                  />
                </Col>
                <Col lg="4" className="text-center">
                  <Input
                    type="number"
                    name="retirementCY"
                    defaultValue={incomeData.retirementCY}
                    onChange={(e) =>
                      setIncomeData((prev) => ({
                        ...prev,
                        retirementCY: e.target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
              <Row className="mt-10">
                <Col lg="4">
                  <Label for="totalIncome">IRA, 401(k)</Label>
                </Col>
                <Col lg="4" className="text-center">
                  <Input
                    type="number"
                    name="iRALY"
                    defaultValue={incomeData.iRALY}
                    onChange={(e) =>
                      setIncomeData((prev) => ({
                        ...prev,
                        iRALY: e.target.value,
                      }))
                    }
                  />
                </Col>
                <Col lg="4" className="text-center">
                  <Input
                    type="number"
                    name="iRACY"
                    defaultValue={incomeData.iRACY}
                    onChange={(e) =>
                      setIncomeData((prev) => ({
                        ...prev,
                        iRACY: e.target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
              <Row className="mt-10">
                <Col lg="4">
                  <Label for="totalIncome">H.S.A account</Label>
                </Col>
                <Col lg="4" className="text-center">
                  <Input
                    type="number"
                    name="accountHSALY"
                    defaultValue={incomeData.accountHSALY}
                    onChange={(e) =>
                      setIncomeData((prev) => ({
                        ...prev,
                        accountHSALY: e.target.value,
                      }))
                    }
                  />
                </Col>
                <Col lg="4" className="text-center">
                  <Input
                    type="number"
                    name="accountHSACY"
                    defaultValue={incomeData.accountHSACY}
                    onChange={(e) =>
                      setIncomeData((prev) => ({
                        ...prev,
                        accountHSACY: e.target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
              <Row className="mt-10">
                <Col lg="4">
                  <Label for="totalIncome">TAX Rate</Label>
                </Col>
                <Col lg="4" className="text-center">
                  <Input
                    type="number"
                    name="taxRateLY"
                    defaultValue={incomeData.taxRateLY}
                    onChange={(e) =>
                      setIncomeData((prev) => ({
                        ...prev,
                        taxRateLY: e.target.value,
                      }))
                    }
                  />
                </Col>
                <Col lg="4" className="text-center">
                  <Input
                    type="number"
                    name="taxRateCY"
                    defaultValue={incomeData.taxRateCY}
                    onChange={(e) =>
                      setIncomeData((prev) => ({
                        ...prev,
                        taxRateCY: e.target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
              <Row className="mt-10">
                <Col lg="4">
                  <Label for="totalIncome">Expenses ratio</Label>
                </Col>
                <Col lg="4" className="text-center">
                  <Input
                    type="number"
                    name="expensesRatioLY"
                    defaultValue={incomeData.expensesRatioLY}
                    onChange={(e) =>
                      setIncomeData((prev) => ({
                        ...prev,
                        expensesRatioLY: e.target.value,
                      }))
                    }
                  />
                </Col>
                <Col lg="4" className="text-center">
                  <Input
                    type="number"
                    name="expensesRatioCY"
                    defaultValue={incomeData.expensesRatioCY}
                    onChange={(e) =>
                      setIncomeData((prev) => ({
                        ...prev,
                        expensesRatioCY: e.target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
              <Row className="mt-10">
                <Col lg="4">
                  <Label for="totalIncome">Investment ratio</Label>
                </Col>
                <Col lg="4" className="text-center">
                  <Input
                    type="number"
                    name="investmentRatioLY"
                    defaultValue={incomeData.investmentRatioLY}
                    onChange={(e) =>
                      setIncomeData((prev) => ({
                        ...prev,
                        investmentRatioLY: e.target.value,
                      }))
                    }
                  />
                </Col>
                <Col lg="4" className="text-center">
                  <Input
                    type="number"
                    name="investmentRatioCY"
                    defaultValue={incomeData.investmentRatioCY}
                    onChange={(e) =>
                      setIncomeData((prev) => ({
                        ...prev,
                        investmentRatioCY: e.target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => setIncomeModal(false)}>
                Cancel
              </Button>{" "}
              &nbsp;
              <Button color="primary" type="submit">
                {isEdit ? "Update" : "Add"}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    </>
  );
}

export default Clients;
