import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import App from "App";
import store from "store";
import { Provider } from "react-redux";
import { ConfigProvider } from "react-avatar";

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider colors={["red", "green", "blue"]}>
      <App />
    </ConfigProvider>
  </Provider>,
  document.getElementById("root")
);
