import React from "react";
import { Row, Col, Button } from "reactstrap";
import Chart from "../../Charts/Chart";
import Header from "../navbar/Header";
import Footer from "../Footer/Footer";
import Qoute from "../Qoute/Qoute";
import { Link } from "react-router-dom"; 

const Main = () => {
  return (
    <>
      <div className="pb-5">
        <Header />
      </div>
      <div className="main-body">
        <Row className="main-body-row">
          <div id="home-back-content">
            <Col className="header-content">
              <h2 className="heading-1">Build Wealth</h2>
              <h2 className="heading-2">Reach American Dream</h2>
              <h2 className="heading-3">Have A Real Impact</h2>
              <Link to="/signup">
              <Button className="header-content-btn">Get Started</Button>
              </Link>
             
              <br />
              <div className="what-we-do-box shadow">
                <p>What We Do</p>
                <h2>Take Your American Dream To The Next Level starting With A Financial Roadmap...</h2>
              </div>
            </Col>
          </div>
        </Row>

        <Row className="chart-area-row">
          <Col className="chart-area-col">
            <div className="chart-area">
              <Chart />
            </div>
          </Col>
        </Row>
       <Qoute/>
        <Footer />
      </div>
    </>
  );
};

export default Main;
