import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  userLoginReducer,
  userListReducer,
  googleFormResListReducer,
  googleFormResByIdReducer,
  disposableIncomeResByEmailReducer,
  disposableIncomeResByUserReducer,
  totalNetWorthResByUserReducer,
  googleFormResByEmailReducer,
} from "./reducer/userReducers";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  usersList: userListReducer,
  googleFormResList: googleFormResListReducer,
  googleFormResById: googleFormResByIdReducer,
  disposableIncomeResByEmail: disposableIncomeResByEmailReducer,
  disposableIncomeResByUser: disposableIncomeResByUserReducer,
  totalNetWorthResByUser: totalNetWorthResByUserReducer,
  googleFormResByEmail: googleFormResByEmailReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
