import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NotificationAlert from "react-notification-alert";
import axios from "axios";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import {
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS,
} from "../constants/userConstants";
import logo from "../assets/img/logo.png";
import ReCAPTCHA from "react-google-recaptcha";
import { login, loginCheckCode } from "actions/userActions";
import {
  URL,
  GOOGLE_CLIENT_ID,
  FACEBOOK_APP_ID,
  RECAPTCHA_SITE_KEY,
} from "../shared/constants";
import { YODLEE_API_URL, YODLEE_CLIENT_ID, YODLEE_SECRET, YODLEE_ADMIN_LOGINNAME } from "../shared/constants";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import qs from "qs";

const Login = () => {
  const notificationAlert = React.useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginCode, setLoginCode] = useState("");
  const [verified, setVerified] = useState(false);
  const [isNewLogin, setIsNewLogin] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, userInfo, error } = userLogin;

  var formOptions = {
    place: "tr",
    message: (
      <div>
        <div>Fields can not be Empty!</div>
      </div>
    ),
    type: "danger",
    icon: "nc-icon nc-bell-55",
    autoDismiss: 4,
  };

  useEffect(() => {
    if (userInfo) {
      if (userInfo.loginWithCode) {
        setIsNewLogin(true);
        formOptions.message = userInfo.message;
        notificationAlert.current.notificationAlert(formOptions);
      } else if (userInfo.isAdmin) {
        history.push("/admin/dashboard");
      } else {
        // history.push("/user/dashboard");
        history.push("/user/today");
      }
    }
    if (error) {
      formOptions.message = error;
      notificationAlert.current.notificationAlert(formOptions);
    }
  }, [userInfo, error, history]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      notificationAlert.current.notificationAlert(formOptions);
    } else {
      dispatch(login(email, password));
    }
  };

  const onSuccessGoogle = async (res) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${URL}/auth/googleLogin`,
        { userData: res.profileObj },
        config
      );

      if (data && data.ifNew && data.ifNew === true) {
        const YodleeURL = `${YODLEE_API_URL}/auth/token`;
        const bodyData = { clientId: YODLEE_CLIENT_ID, secret: YODLEE_SECRET };
        const options = {
          method: "POST",
          headers: {
            "Api-Version": "1.1",
            loginName: YODLEE_ADMIN_LOGINNAME,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: qs.stringify(bodyData),
          url: YodleeURL,
        };
        const yodleeTokenRes = await axios(options);

        //

        const YodleeREGURL =
          "https://development.api.yodlee.com/ysl/user/register";

        const bodyData2 = {
          user: {
            loginName: String(data.email),
          },
        };

        console.log(bodyData2)

        const optionss = {
          method: "POST",
          headers: {
            "Api-Version": "1.1",
            // loginName: userLoginName,
            "Content-Type": "application/json",
            Authorization: "Bearer " + yodleeTokenRes.data.token.accessToken,
          },
          data: bodyData2,
          url: YodleeREGURL,
        };
        await axios(optionss);
      }

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });
      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: error.response.data.message,
      });
    }
  };

  const onFailureGoogle = (res) => {
    console.log("Google Login failed: ", res);
  };

  const onSuccessFacebook = async (res) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${URL}/auth/facebookLogin`,
        { userData: res },
        config
      );

      if (data && data.ifNew && data.ifNew === true) {
        const YodleeURL = `${YODLEE_API_URL}/auth/token`;
        const bodyData = { clientId: YODLEE_CLIENT_ID, secret: YODLEE_SECRET };
        const options = {
          method: "POST",
          headers: {
            "Api-Version": "1.1",
            loginName: YODLEE_ADMIN_LOGINNAME,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: qs.stringify(bodyData),
          url: YodleeURL,
        };
        const yodleeTokenRes = await axios(options);

        //

        const YodleeREGURL =
          "https://development.api.yodlee.com/ysl/user/register";

        const bodyData2 = {
          user: {
            loginName: String(data.email),
          },
        };

        console.log(bodyData2)

        const optionss = {
          method: "POST",
          headers: {
            "Api-Version": "1.1",
            // loginName: userLoginName,
            "Content-Type": "application/json",
            Authorization: "Bearer " + yodleeTokenRes.data.token.accessToken,
          },
          data: bodyData2,
          url: YodleeREGURL,
        };
        await axios(optionss);
      }
      
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });
      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: error.response.data.message,
      });
    }
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    if (loginCode === "") {
      notificationAlert.current.notificationAlert(formOptions);
    } else {
      dispatch(loginCheckCode(loginCode));
    }
  };

  return (
    <>
      {!isNewLogin ? (
        <div
          className="w-100 border"
          style={{ minHeight: "100vh", backgroundColor: "#f4f3ef" }}
        >
          <NotificationAlert ref={notificationAlert} />
          <Row className="mx-auto mt-5 card-login">
            <Col>
              <div className="text-center">
                <Link to="/home">
                  <img src={logo} alt="logo" className="my-5 text-center" />
                </Link>
              </div>
              <Card className="card-user p-4">
                <CardHeader>
                  <CardTitle tag="h5" className="text-center">
                    Sign In
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label htmlFor="exampleInputEmail1">
                            Email address
                          </label>
                          <Input
                            placeholder="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Password</label>
                          <Input
                            placeholder="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <div className="captcha">
                          <ReCAPTCHA
                            style={{ display: "inline-block" }}
                            sitekey={RECAPTCHA_SITE_KEY}
                            onChange={(e) => setVerified(true)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Link to="/forgot-password">Forgot password?</Link>
                      </Col>
                    </Row>
                    <Row>
                      <div className="update ml-auto mr-auto">
                        <Button
                          disabled={!verified}
                          className="btn-round"
                          color="success"
                          type="submit"
                        >
                          <div className="px-4">
                            {loading ? (
                              <Spinner animation="border" size={"sm"} />
                            ) : (
                              "Sign In"
                            )}
                          </div>
                        </Button>
                      </div>
                    </Row>
                    <Row>
                      <Col md="12" lg="6" className="text-center mt-2">
                        <GoogleLogin
                          clientId={GOOGLE_CLIENT_ID}
                          buttonText="Login With Google"
                          onSuccess={onSuccessGoogle}
                          onFailure={onFailureGoogle}
                          cookiePolicy={"single_host_origin"}
                        />
                      </Col>
                      <Col md="12" lg="6" className="text-center mt-2">
                        <FacebookLogin
                          textButton="Login With Facebook"
                          size="small"
                          scope="public_profile, email"
                          appId={FACEBOOK_APP_ID}
                          icon="fa-facebook"
                          cssClass="btnFacebook"
                          fields="name,email,picture"
                          callback={onSuccessFacebook}
                        />
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <div
          className="w-100 border"
          style={{ minHeight: "100vh", backgroundColor: "#f4f3ef" }}
        >
          <NotificationAlert ref={notificationAlert} />
          <Row className="mx-auto mt-5 card-Login">
            <Col>
              <div className="text-center">
                <Link to="/home">
                  <img src={logo} alt="logo" className="my-5 text-center" />
                </Link>
              </div>
              <Card className="card-user p-4">
                <CardHeader>
                  <CardTitle tag="h5" className="text-center">
                    Confirmation Code
                  </CardTitle>
                </CardHeader>
                <CardBody className="login-code-body">
                  <Form onSubmit={handleCodeSubmit}>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label htmlFor="exampleInputEmail1">Login Code</label>
                          <Input
                            placeholder="Login Code"
                            type="loginCode"
                            value={loginCode}
                            onChange={(e) => setLoginCode(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <div className="update ml-auto mr-auto">
                        <Button
                          disabled={!loginCode}
                          className="btn-round"
                          color="success"
                          type="submit"
                        >
                          <div className="px-4">
                            {loading ? (
                              <Spinner animation="border" size={"sm"} />
                            ) : (
                              "Sign In"
                            )}
                          </div>
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};
export default Login;
