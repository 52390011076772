import React, { useEffect }  from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, useLocation } from "react-router-dom";
import Footer from "../landing/components/Footer/Footer.js";
import userRoutes from "../userRoutes";
import UserNavbar from "user/components/navbar/UserNavbar";

let ps;

function Dashboard(props) {
  const mainPanel = React.useRef();
  const location = useLocation();
  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.body.classList.toggle("perfect-scrollbar-on");
      }
    };
  });
  useEffect(() => {
    mainPanel.current.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);
  return (
    <>
      <div className="user-navbar" ref={mainPanel}>
        <UserNavbar {...props} />
      </div>
      <div className="coponent-render">
        <Switch>
          {userRoutes.map((prop, key) => {
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          })}
        </Switch> 
        </div>
        <div>
        <Footer fluid />
      </div>
    </>
  );
}

export default Dashboard;
