import React, { useState, useEffect } from "react";
import goal1 from "../../../landing/assets/images/SMgoals.png";
import goal2 from "../../../landing/assets/images/retirement.png";
import goal3 from "../../../landing/assets/images/generation.png";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { getGoogleFormResByEmail } from "../../../actions/googleFormActions";

const Dream = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [googleFormResModel, setGoogleFormResModel] = useState([]);
  const [toggleGraph, setToggleGraph] = useState("Wealth Projection");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const modelData = useSelector((state) => state.googleFormResByEmail);
  const { googleFormResponsesByEmail } = modelData;

  useEffect(() => {
    if (userInfo) {
      dispatch(getGoogleFormResByEmail(userInfo.email));
    }
  }, [userInfo, dispatch]);

  useEffect(() => {
    if (googleFormResponsesByEmail) {
      setGoogleFormResModel(googleFormResponsesByEmail);
    } else {
      let dummyRes = [{ answer: "No data available"}];
      setGoogleFormResModel(dummyRes);
    }
  }, [googleFormResponsesByEmail]);

  const data = {
    series: [
      {
        name: "Wealth Projection",
        data: [3, 8, 6, 15, 12, 33],
      },
      {
        name: "Texable Investment",
        data: [11, 32, 45, 32, 34, 53],
      },
      {
        name: "Tax-Advantage Investment",
        data: [6, 14, 30, 23, 14, 64],
      },
      {
        name: "Saving Cushion",
        data: [6, 43, 39, 54, 78, 74],
      },
    ],
    options: {
      colors: ["#2948FF", "#CFA925", "#02AD88", "#ED7E30"],
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        events: {
          legendClick: (chartContext, seriesIndex, config) => {
            setToggleGraph(config.globals.seriesNames[seriesIndex]);
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: ["Yr 2", "Yr 10", "Yr 15", "Yr 20", "Yr 25", "Yr 30"],
        colors: ["#AAB0BE"],
      },
      yaxis: {
        opposite: true,
        labels: {
          formatter: function (val, index) {
            return "$" + val.toFixed(2) + "mil";
          },
        },
      },
      legend: {
        horizontalAlign: "left",
        onItemClick: {
          toggleDataSeries: true,
        },
      },
      legend: { position: "bottom", textStyle: { fontSize: 16 } },
    },
  };

  const openBudgetGoogleForm = () => {
    window.open("https://docs.google.com/forms/d/e/1FAIpQLSd9Bhz4eA6p85h7FvoltHddO8zOyOCmdwT2RMdnBlrNZy159g/viewform", "_blank");
  }

  return (
    <>
      <section className="goals py-5 mt-5">
        <div className="container">
          <div className="row mt-5">
            <h1 className="col-md-12 text-center py-4 lifetime-heading">
              Lifetime Goals
            </h1>
            <div className="col-md-12 col-lg-4 mt-3">
              <div className="goals-list p-5">
                <img src={goal1} alt="short and medium goals" />
                <h4 className="mt-3">Short & Medium Term Goals</h4>
                <p>
                  Set 1~5 year goals and make budgeting and corresponding
                  investment decisions.
                </p>

                <button
                  className="p-2 text-white btn-read"
                  onClick={() => setModal(true)}
                >
                  Read More
                </button>
              </div>
            </div>

            <div className="col-md-12 col-lg-4 mt-3">
              <div className="goals-list p-5">
                <img src={goal2} alt="wealth transfer" />
                <h4 className="mb-5 mt-3">Retirement Goal</h4>
                <p className="pt-2 mr-5">
                  Know with confidence when you can retire and what to expect.
                </p>

                <button
                  className="p-2 text-white btn-read"
                  onClick={() => setModal1(true)}
                >
                  Read More
                </button>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 mt-3">
              <div className="goals-list p-5">
                <img src={goal3} alt="wealth transfer" />
                <h4 className="mt-3">Generation Wealth Transfer</h4>
                <p>
                  Avoid the pitfalls and maximize post-tax assets transferred to
                  your future generations.
                </p>

                <button
                  className="p-2 text-white  btn-read"
                  onClick={() => setModal2(true)}
                >
                  Read More
                </button>
              </div>
            </div>
            <button className="user-dream-btn" onClick={openBudgetGoogleForm}>
              Click Here To Set Your Lifetime Goals And Risk Tolerance
            </button>
          </div>
        </div>
      </section>

      {/* wealth projection */}
      <section className="projection py-5">
        <h1 className="projection-heading text-center py-5">{toggleGraph}</h1>

        <div className="container ">
          <Card className="py-5 px-3">
            <div id="chart">
              <Chart
                className="mr-5"
                options={data.options}
                series={data.series}
                type="area"
                height={350}
              />
            </div>
          </Card>
        </div>
      </section>

      <Modal isOpen={modal} centered>
        <ModalHeader charCode="X">Short & Medium Term Goals</ModalHeader>
        <ModalBody>
          <ul className="modal-ul">
            {googleFormResModel &&
              googleFormResModel.map((item, index) => {
                return <li key={index} className="modal-li">{item.answer}</li>;
              })}
          </ul>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModal(false)}>
            Close
          </Button>{" "}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modal1} centered>
        <ModalHeader charCode="X">Retirement Goal</ModalHeader>
        <ModalBody>
          <ul className="modal-ul">
            <li className="modal-li">
              No data available
            </li>
          </ul>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModal1(false)}>
            Close
          </Button>{" "}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modal2} centered>
        <ModalHeader charCode="X">Generation Wealth Transfer</ModalHeader>
        <ModalBody>
          <ul className="modal-ul">
            <li className="modal-li">
              No data available
            </li>
          </ul>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModal2(false)}>
            Close
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Dream;
