import Dashboard from "../src/user/components/views/Dashboard";
import Today from "../src/user/components/views/Today";
import Pillar from "../src/user/components/views/Pillars";
import Dream from "../src/user/components/views/Dream";

var userRoutes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   component: Dashboard,
  //   layout: "/user",
  // },
  {
    path: "/today",
    name: "Today",
    component: Today,
    layout: "/user",
  },
  {
    path: "/how-to-get-there",
    name: "How to get there",
    component: Pillar,
    layout: "/user",
  },
  {
    path: "/american-dream",
    name: "Dream",
    component: Dream,
    layout: "/user",
  },
];
export default userRoutes;
