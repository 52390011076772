import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotificationAlert from "react-notification-alert";
import DataTable from "react-data-table-component";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  ModalFooter,
} from "reactstrap";
import {
  getGoogleFormResList,
  getGoogleFormResById,
} from "../actions/googleFormActions";

function GoogleFormResponses() {
  const notificationAlert = React.useRef();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [searchGoogleFormRes, setSearchGoogleFormRes] = useState([]);
  const [googleFormResList, setGoogleFormResList] = useState([]);
  const [googleFormResModel, setGoogleFormResModel] = useState([]);

  const list = useSelector((state) => state.googleFormResList);
  const { googleFormResponses } = list;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const modelData = useSelector((state) => state.googleFormResById);
  const { questionAnswerResponses } = modelData;

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(getGoogleFormResList());
    }
  }, [userInfo, dispatch]);

  useEffect(() => {
    if (googleFormResponses) {
      setGoogleFormResList(googleFormResponses);
      setSearchGoogleFormRes(googleFormResponses);
    }
  }, [googleFormResponses]);

  const handleSearch = (e) => {
    var value = e.target.value.toLowerCase();
    var arr = [];
    googleFormResList.map((el) => {
      let id = el.id.toLowerCase();
      let email = el.email.toLowerCase();
      if (email.includes(value) || id.includes(value)) {
        arr.push(el);
        setSearchGoogleFormRes(arr);
      } else {
        setSearchGoogleFormRes(arr);
      }
    });
  };

  const handleGoogleResponseById = (id) => {
    if (id) {
      dispatch(getGoogleFormResById(id));
    }
  };

  useEffect(() => {
    if (questionAnswerResponses) {
      setGoogleFormResModel(questionAnswerResponses);
    }
  }, [questionAnswerResponses]);

  const columns = [
    {
      name: "Google Form Id",
      selector: (row) => row.id,
    },
    {
      name: "Users",
      selector: (row) => row.email,
    },
    {
      cell: (row) => (
        <>
          <Button
            size="sm"
            onClick={() => {
              handleGoogleResponseById(row.id);
              setModal(true);
            }}
            className="btn-secondary"
            id={row.id}
          >
            View
          </Button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <>
      <div className="content">
        <NotificationAlert ref={notificationAlert} />

        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Col md="12">
                  <Card className="card-plain">
                    <CardHeader>
                      <div className="d-flex justify-content-between google-form-response-text">
                        <CardTitle tag="h4">Google Form Responses</CardTitle>

                        <InputGroup className="no-border w-50 p-0">
                          <Input
                            placeholder="Search..."
                            onChange={handleSearch}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>
                              <i className="nc-icon nc-zoom-split" />
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </div>
                      <p className="card-category">
                        Here is list of All Google Form Responses
                      </p>
                    </CardHeader>
                    <CardBody>
                      <DataTable
                        columns={columns}
                        data={searchGoogleFormRes}
                        pagination
                        highlightOnHover
                      />
                    </CardBody>
                  </Card>
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal isOpen={modal} centered>
          <Form>
            <ModalHeader charCode="X">View Google Form Response</ModalHeader>
            <ModalBody>
              {googleFormResModel &&
                googleFormResModel.map((item, index) => {
                  return <FormGroup>
                    <Label for={index.toString()}>{item.question}</Label>
                    <Input
                      name={item.answer}
                      id={index}
                      disabled
                      defaultValue={item.answer}
                    />
                  </FormGroup>;
                })}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => setModal(false)}>
                Cancel
              </Button>{" "}
              &nbsp;
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    </>
  );
}

export default GoogleFormResponses;
