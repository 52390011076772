import { React } from "react";
import img1 from "../../../landing/assets/images/goals.png";
import img2 from "../../../landing/assets/images/planning.png";
import img3 from "../../../landing/assets/images/asset.png";

const Pillar = () => {
  const openTaxConsultationForm = () => {
    window.open("https://maagoncpa.as.me/go", "_blank");
  }
  return (
    <>
      <h1 className="space">
        Three Pillars <br />
        Of Wealth Building
      </h1>
      {/* First Container  */}
      <div className="goal-user">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mt-3">
              <div className="mobile mt-5R">
                <div className="textcont-user text-capitalize">
                  Maximize Long-Term Investment Return
                </div>
                <p className="lifegoals-text">
                  <i className="fa-thin fa-triangle"></i>
                  <i className="fa fa-minus dash-icon"></i>Maximize Disposable
                  Income
                  <br />
                  <i className="fa fa-minus dash-icon"></i>Elite Tax Strategy
                  <br />
                  <i className="fa fa-minus dash-icon"></i>Life Goals Setting
                  <br />
                  <i className="fa fa-minus dash-icon"></i>Risk Tolerane Assessment
                  <br />
                  <i className="fa fa-minus dash-icon"></i>Budgeting
                </p>

                <div className="d-flex">
                  <i className="fa fa-play" aria-hidden="true"></i>
                  <button className="btn-long-term text-capitalize" onClick={openTaxConsultationForm}>
                      Click Here to access my maagon fund performance
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-6 spacing mt-3">
              <img src={img1} alt="Life-goals" className="image-lifegoals" />
              <p className="index">01</p>
            </div>
          </div>
        </div>
      </div>

      {/* Second Container  */}
      <div className="planning planning-user-custom">
        <div className="container ">
          <div className="row">
            <div className="col-md-6 mt-3">
              <div className="cont2-responsive">
                <img
                  src={img2}
                  alt="Life-time-wealth"
                  className="img-life-time-wealth"
                />
                <p className="index2">02</p>
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <div className="planing-content">
                <div className="textcont-user text-capitalize">
                  tax planning and Disposable income
                </div>
                <p className="lifegoals-text">
                  <i className="fa fa-minus dash-icon"></i>Tax Planning and
                  Assessment
                  <br />
                  <i className="fa fa-minus dash-icon"></i>Maximize Disposable
                  Income
                  <br />
                  <i className="fa fa-minus dash-icon"></i>Elite Tax Strategies
                  <br />
                  <i className="fa fa-minus dash-icon"></i>Estate Planning
                </p>

                <div className="d-flex">
                  <i className="fa fa-play" aria-hidden="true"></i>
                  <button className="btn-planning-call text-capitalize" onClick={openTaxConsultationForm}>
                      schedule tax planing call
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Third Container  */}
      <div className="asset asset-user-custom">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mt-3">
              <div className="cont3-responsive">
                <div className="textcont-user text-capitalize">
                  Lifetime wealth planning
                </div>
                <p className="lifegoals-text">
                  <i className="fa fa-minus dash-icon"></i>Life Goals Setting
                  <br />
                  <i className="fa fa-minus dash-icon"></i>Budgeting
                  <br />
                  <i className="fa fa-minus dash-icon"></i>Personalized Retirement
                  planning
                  <br />
                  <i className="fa fa-minus dash-icon"></i>Generational Wealth
                  Planning
                </p>

                <div className="d-flex">
                  <i className="fa fa-play" aria-hidden="true"></i>
                  <button className="btn-schedule-call text-capitalize" onClick={openTaxConsultationForm}>
                      Schedule financial planning call
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-6 spacing mt-3">
              <img
                src={img3}
                alt="Asset-management"
                className="img-asset-management"
              />
              <p className="index3">03</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Pillar;
