import Dashboard from "views/Dashboard.js";
// import Notifications from "views/Notifications.js";
// import Icons from "views/Icons.js";
// import Typography from "views/Typography.js";
// import TableList from "views/Tables.js";
import AddUser from "views/AddUser.js";
import Profile from "./views/Profile";
import Clients from "views/Clients";
import GoogleFormResponses from "./views/GoogleFormResponses";
import PM from "views/PM";
// import UpgradeToPro from "views/Upgrade.js";

var adminRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin",
  },

  {
    path: "/add-user",
    name: "Add User",
    icon: "nc-icon nc-simple-add",
    component: AddUser,
    layout: "/admin",
  },
  {
    path: "/clients",
    name: "Clients",
    icon: "nc-icon nc-circle-10",
    component: Clients,
    layout: "/admin",
  },
  {
    path: "/pms",
    name: "Portal Managers",
    icon: "nc-icon nc-circle-10",
    component: PM,
    layout: "/admin",
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-diamond",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   component: Notifications,
  //   layout: "/admin",
  // },
  {
    path: "/google-form",
    name: "Google Form Responses",
    icon: "nc-icon nc-paper",
    component: GoogleFormResponses,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "Profile",
    icon: "nc-icon nc-single-02",
    component: Profile,
    layout: "/admin",
  },
  // {
  //   path: "/tables",
  //   name: "Table List",
  //   icon: "nc-icon nc-tile-56",
  //   component: TableList,
  //   layout: "/admin",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "nc-icon nc-caps-small",
  //   component: Typography,
  //   layout: "/admin",
  // },
  // {
  //   pro: true,
  //   path: "/upgrade",
  //   name: "Upgrade to PRO",
  //   icon: "nc-icon nc-spaceship",
  //   component: UpgradeToPro,
  //   layout: "/admin",
  // },
];
export default adminRoutes;
