import { React } from "react";
import { Link } from "react-router-dom";
import img1 from "../../../landing/assets/images/goals.png";
import img2 from "../../../landing/assets/images/planning.png";
import img3 from "../../../landing/assets/images/asset.png"; 
import AppHeader from "../navbar/AppHeader";
import Footer from "../Footer/Footer";
import Qoute from "../Qoute/Qoute";
import { BsArrowRightShort } from "react-icons/bs";

const Pillar = () => {
  return (
    <>
      <AppHeader />
      <h1 className="space ">
        The Three Pillars <br />
        Of Wealth Building
      </h1>

      <section className="get-start ">
        <div className="d-flex justify-content-between ">
          {/* <div className="textdecor" id="beleive-service">
            People Love Us & <br />
            Believe Our Services.
          </div> */}

          <div id="buttn">
            <Link to="/signup">
              {/* <button type="button" className="btn-get-started butn"> */}
                {/* Get Started */}
                {/* <BsArrowRightShort/> */}
                {/* <img id="icon" src={icon} alt="right-arrow" /> */}
              {/* </button> */}
            </Link>
          </div>
        </div>
      </section>

      {/* First Container  */}
      <div className="goal">
        <div className="container">
          <div className="row">
            <div className="col-md-6 spacing">
              <img src={img1} alt="Life-goals" className="image-lifegoals" />
              <p className="index">01</p>
            </div>

            <div className="col-md-6">
              <div className="mobile">
                <div className="textcont">
                  Life Goals, Taxes & <br />
                  Disposable Income
                </div>
                <p className="lifegoals-text">
                  <i className="fa-solid fa-check check-yellow"></i>Maximize
                  Disposable Income
                  <br />
                  <i className="fa-solid fa-check check-yellow"></i>Elite Tax
                  Strategy
                  <br />
                  <i className="fa-solid fa-check check-yellow"></i>Life Goals
                  Setting
                  <br />
                  <i className="fa-solid fa-check check-yellow"></i>Risk Tolerane
                  Assessment
                  <br />
                  <i className="fa-solid fa-check check-yellow"></i>Budgeting
                </p>
                <Link to="/signup">
                  <button type="button" className="btn-max-tax-savings">
                    Maximize Tax Savings
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Second Container  */}
      <div className="planning">
        <div className="container ">
          <div className="row">
            <div className="col-md-6">
              <div className="planing-content">
                <div className="textcont">
                  Lifetime Wealth <br />
                  Planning
                </div>
                <p className="lifegoals-text">
                  <i className="fa-solid fa-check check-yellow"></i>Risk-Based Asset
                  Allocation
                  <br />
                  <i className="fa-solid fa-check check-yellow"></i>Personalized
                  Retirement Plans
                  <br />
                  <i className="fa-solid fa-check check-yellow"></i>Generational
                  Wealth Transfer Guide
                </p>
                <Link to="/signup">
                  <button type="button" className="btn-plan-my-wealth">
                    Plan My Wealth
                  </button>
                </Link>
              </div>
            </div>

            <div className="col-md-6">
              <div className="cont2-responsive">
                <img
                  src={img2}
                  alt="Life-time-wealth"
                  className="img-life-time-wealth"
                />
                <p className="index2">02</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Third Container  */}
      <div className="asset">
        <div className="container">
          <div className="row">
            <div className="col-md-6 spacing">
              <img
                src={img3}
                alt="Asset-management"
                className="img-asset-management"
              />
              <p className="index3">03</p>
            </div>

            <div className="col-md-6">
              <div className="cont3-responsive">
                <div className="textcont">Asset Management</div>
                <p className="lifegoals-text">
                  <i className="fa-solid fa-check check-yellow"></i>Active Asset
                  Management
                  <br />
                  <i className="fa-solid fa-check check-yellow"></i>Impact
                  Investment
                  <br />
                  <i className="fa-solid fa-check check-yellow"></i>Investment
                  Review
                </p>
                <Link to="/signup">
                  <button type="button" className="btn-start-asset-management">
                    Start Asset Management
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Qoute />
      <Footer />
    </>
  );
};
export default Pillar;
