import React from "react";
import { Card, Table } from "reactstrap";
import client from "../../../../src/user/assets/images/client.png";
import Invoice from "../../../../src/user/assets/images/invoice.png";
import projects from "../../../../src/user/assets/images/projects.png";
import right_icon from "../../../../src/user/assets/images/forward-icon.png";
import joseph from "../../../../src/user/assets/images/joseph.png";
import Chart from "react-apexcharts";
const Dashboard = () => {
  const data = {
    series: [
      {
        name: "Site A",
        data: [31, 40, 28, 51, 42, 109, 100],
      },
      {
        name: "Site B",
        data: [11, 32, 45, 32, 34, 52, 41],
      },
      {
        name: "Site C",
        data: [6, 14, 30, 23, 14, 26, 14],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: ["2010", "2011", "2012", "2013", "2014", "2015", "2016"],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  };

  return (
    <>
      <div className="user-dashboard">
        <div className="dashboard-top d-flex justify-content-between">
          <h3>Dashboard</h3>
          <p>search bar</p>
        </div>

        {/* <section className="dashboard-cards">
          <div className="row">
            <div className="col-md-4">
              <Card className="px-5">
                <div className="d-flex justify-content-between  ">
                  <div className="d-flex">
                    <div className="img-background mr-4">
                      <img src={client} className="" />
                    </div>
                    <div className="mt-2 card-text">
                      <p className="mb-0 ">My Client</p>
                      <h3 className="mb-0">825</h3>
                    </div>
                  </div>
                  <div className="forward-icon">
                    <img src={right_icon} />
                  </div>
                </div>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="px-5">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <div className="img-background mr-4">
                      <img src={projects} />
                    </div>
                    <div className="mt-2 card-text">
                      <p className="mb-0">New Projects</p>
                      <h3 className="mb-0">74</h3>
                    </div>
                  </div>
                  <div className="forward-icon">
                    <img src={right_icon} />
                  </div>
                </div>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="px-5">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <div className="img-background mr-4">
                      <img src={Invoice} />
                    </div>
                    <div className="mt-2 card-text">
                      <p className="mb-0">New Invoices</p>
                      <h3 className="mb-0">157</h3>
                    </div>
                  </div>
                  <div className="forward-icon">
                    <img src={right_icon} />
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </section> */}
        {/***********************Chart Section*************************/}
        <section>
          <Card>
            {/* <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <div>
                    <p>Visit</p>
                    <div className="d-flex">
                      <img />
                      <p>1064</p>
                    </div>
                    <img />
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <p>Total Page Views</p>
                    <div className="d-flex">
                      <img />
                      <p>5064</p>
                    </div>
                    <img />
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <p>Unique Visitor</p>
                    <div className="d-flex">
                      <img />
                      <p>664</p>
                    </div>
                    <img />
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <p>Bounce Rate</p>
                    <div className="d-flex">
                      <img />
                      <p>50%</p>
                    </div>
                    <img />
                  </div>
                </div>
              </div>
            </div> */}
            <div>
              <Chart
                className=""
                options={data.options}
                series={data.series}
                type="area"
                height={350}
              />
            </div>
          </Card>
        </section>
        {/***********************Invoices section****************************/}
        <section className="invoices">
          <div className="invoice-top d-flex justify-content-between">
            <h3 className="mb-0">New Invoices</h3>
            <button>View All Invoices</button>
          </div>
          <Card className="p-5">
            <Table>
              <tbody>
                <tr>
                  <td className="py-5">
                    <p className="mb-0">invoice #14253</p>
                    <h3 className="mb-0">Landing Page Design</h3>
                  </td>
                  <td>
                    <img src={joseph} className="mr-3"/>
                    Joseph Flint
                  </td>
                  <td>joseph.flint@gmail.com</td>
                  <td>12 Oct, 19</td>
                  <td>$ 1189</td>
                </tr>
                <tr>
                  <td className="py-5">
                    <p className="mb-0">invoice #14253</p>
                    <h3 className="mb-0">Landing Page Design</h3>
                  </td>
                  <td>
                    <img src={joseph} className="mr-3" />
                    Joseph Flint
                  </td>
                  <td>joseph.flint@gmail.com</td>
                  <td>12 Oct, 19</td>
                  <td>$ 1189</td>
                </tr>
                <tr>
                  <td className="py-5">
                    <p className="mb-0">invoice #14253</p>
                    <h3 className="mb-0">Landing Page Design</h3>
                  </td>
                  <td>
                    <img src={joseph} className="mr-3" />
                    Joseph Flint
                  </td>
                  <td>joseph.flint@gmail.com</td>
                  <td>12 Oct, 19</td>
                  <td>$ 1189</td>
                </tr>
                <tr>
                  <td className="py-5">
                    <p className="mb-0">invoice #14253</p>
                    <h3 className="mb-0">Landing Page Design</h3>
                  </td>
                  <td>
                    <img src={joseph} className="mr-3" />
                    Joseph Flint
                  </td>
                  <td>joseph.flint@gmail.com</td>
                  <td>12 Oct, 19</td>
                  <td>$ 1189</td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </section>
      </div>
    </>
  );
};
export default Dashboard;
