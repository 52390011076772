import { USER_LOGIN_FAIL } from "constants/userConstants";
// import { USER_REGISTER_REQUEST } from "constants/userConstants";
// import { USER_REGISTER_FAIL } from "constants/userConstants";
// import { USER_REGISTER_SUCCESS } from "constants/userConstants";
import { USER_DETAILS_SUCCESS } from "constants/userConstants";
import { USER_DETAILS_RESET } from "constants/userConstants";
import { USER_LIST_REQUEST } from "constants/userConstants";
// import { USER_UPDATE_PROFILE_FAIL } from "constants/userConstants";
import { USER_LIST_SUCCESS } from "constants/userConstants";
import { USER_LIST_RESET } from "constants/userConstants";
import { USER_DELETE_SUCCESS } from "constants/userConstants";
// import { USER_UPDATE_REQUEST } from "constants/userConstants";
// import { USER_UPDATE_FAIL } from "constants/userConstants";
// import { USER_UPDATE_RESET } from "constants/userConstants";
// import { USER_UPDATE_SUCCESS } from "constants/userConstants";
import { USER_DELETE_FAIL } from "constants/userConstants";
import { USER_DELETE_REQUEST } from "constants/userConstants";
import { USER_LIST_FAIL } from "constants/userConstants";
// import { USER_UPDATE_PROFILE_RESET } from "constants/userConstants";
// import { USER_UPDATE_PROFILE_SUCCESS } from "constants/userConstants";
// import { USER_UPDATE_PROFILE_REQUEST } from "constants/userConstants";
import { USER_DETAILS_FAIL } from "constants/userConstants";
import { USER_DETAILS_REQUEST } from "constants/userConstants";
import { USER_LOGOUT } from "constants/userConstants";
import {
  USER_LOGIN_SUCCESS,
  USER_LOGIN_REQUEST,
  GOOGLE_FORM_RES_LIST_REQUEST,
  GOOGLE_FORM_RES_LIST_SUCCESS,
  GOOGLE_FORM_RES_LIST_FAIL,
  GOOGLE_FORM_RES_BY_ID_REQUEST,
  GOOGLE_FORM_RES_BY_ID_SUCCESS,
  GOOGLE_FORM_RES_BY_ID_FAIL,
  DISPOSIBLE_EMAIL_RES_LIST_REQUEST,
  DISPOSIBLE_EMAIL_RES_LIST_SUCCESS,
  DISPOSIBLE_EMAIL_RES_LIST_FAIL,
  DISPOSIBLE_USER_RES_LIST_REQUEST,
  DISPOSIBLE_USER_RES_LIST_SUCCESS,
  DISPOSIBLE_USER_RES_LIST_FAIL,
  TOTAL_NETWORTH_USER_RES_LIST_REQUEST,
  TOTAL_NETWORTH_USER_RES_LIST_SUCCESS,
  TOTAL_NETWORTH_USER_RES_LIST_FAIL,
  GOOGLE_FORM_RES_BY_EMAIL_REQUEST,
  GOOGLE_FORM_RES_BY_EMAIL_SUCCESS,
  GOOGLE_FORM_RES_BY_EMAIL_FAIL,
} from "constants/userConstants";

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

// export const userCreateReducer = (state = {}, action) => {
//   switch (action.type) {
//     case USER_CREATE_REQUEST:
//       return { loading: true };
//     case USER_CREATE_SUCCESS:
//       return { loading: false, created: action.payload };
//     case USER_CREATE_FAIL:
//       return { loading: false, error: action.payload };
//     case USER_LOGOUT:
//       return {};
//     default:
//       return state;
//   }
// };

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true };
    case USER_LIST_SUCCESS:
      return { loading: false, users: action.payload };
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case USER_LIST_RESET:
      return { users: [] };
    default:
      return state;
  }
};

export const userDetailsReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case USER_DETAILS_SUCCESS:
      return { loading: false, user: action.payload };
    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_DETAILS_RESET:
      return { user: {} };
    default:
      return state;
  }
};

// export const userUpdateProfileReducer = (state = {}, action) => {
//   switch (action.type) {
//     case USER_UPDATE_PROFILE_REQUEST:
//       return { loading: true };
//     case USER_UPDATE_PROFILE_SUCCESS:
//       return { loading: false, success: true, userInfo: action.payload };
//     case USER_UPDATE_PROFILE_FAIL:
//       return { loading: false, error: action.payload };
//     case USER_UPDATE_PROFILE_RESET:
//       return {};
//     default:
//       return state;
//   }
// };

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true };
    case USER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case USER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// export const userUpdateReducer = (state = { user: {} }, action) => {
//   switch (action.type) {
//     case USER_UPDATE_REQUEST:
//       return { loading: true };
//     case USER_UPDATE_SUCCESS:
//       return { loading: false, success: true };
//     case USER_UPDATE_FAIL:
//       return { loading: false, error: action.payload };
//     case USER_UPDATE_RESET:
//       return {
//         user: {},
//       };
//     default:
//       return state;
//   }
// };

export const googleFormResListReducer = (state = {}, action) => {
  switch (action.type) {
    case GOOGLE_FORM_RES_LIST_REQUEST:
      return { loading: true };
    case GOOGLE_FORM_RES_LIST_SUCCESS:
      return { loading: false, googleFormResponses: action.payload };
    case GOOGLE_FORM_RES_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const googleFormResByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case GOOGLE_FORM_RES_BY_ID_REQUEST:
      return { loading: true };
    case GOOGLE_FORM_RES_BY_ID_SUCCESS:
      return { loading: false, questionAnswerResponses: action.payload };
    case GOOGLE_FORM_RES_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const disposableIncomeResByEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case DISPOSIBLE_EMAIL_RES_LIST_REQUEST:
      return { loading: true };
    case DISPOSIBLE_EMAIL_RES_LIST_SUCCESS:
      return { loading: false, disposableIncomeResponses: action.payload };
    case DISPOSIBLE_EMAIL_RES_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const disposableIncomeResByUserReducer = (state = {}, action) => {
  switch (action.type) {
    case DISPOSIBLE_USER_RES_LIST_REQUEST:
      return { loading: true };
    case DISPOSIBLE_USER_RES_LIST_SUCCESS:
      return { loading: false, disposableIncomeResponses: action.payload };
    case DISPOSIBLE_USER_RES_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const totalNetWorthResByUserReducer = (state = {}, action) => {
  switch (action.type) {
    case TOTAL_NETWORTH_USER_RES_LIST_REQUEST:
      return { loading: true };
    case TOTAL_NETWORTH_USER_RES_LIST_SUCCESS:
      return { loading: false, totalNetWorthResponses: action.payload };
    case TOTAL_NETWORTH_USER_RES_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const googleFormResByEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case GOOGLE_FORM_RES_BY_EMAIL_REQUEST:
      return { loading: true };
    case GOOGLE_FORM_RES_BY_EMAIL_SUCCESS:
      return { loading: false, googleFormResponsesByEmail: action.payload };
    case GOOGLE_FORM_RES_BY_EMAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};