import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { URL } from "shared/constants";
import NotificationAlert from "react-notification-alert";
import axios from "axios";

function Profile() {
  const notificationAlert = React.useRef();
  const [user, setUser] = useState({});
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");

  // const [fname, setFname] = useState("");
  // const [lname, setLname] = useState("");
  // const [address, setAddress] = useState("");
  // const [city, setCity] = useState("");
  // const [country, setCountry] = useState("");
  // const [phone, setPhone] = useState(null);
  

  const userInfo = localStorage.getItem("userInfo");

  React.useEffect(() => {
    setUser(JSON.parse(userInfo));
  }, [userInfo]);

  var formOptions = {
    place: "tr",
    icon: "nc-icon nc-bell-55",
    autoDismiss: 4,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user.fname === "" || user.lname === "" || user.phone === "") {
      formOptions.type = "danger";
      formOptions.message = "Fields can not be Empty";
      notificationAlert.current.notificationAlert(formOptions);
    } else if (password !== confirm) {
      formOptions.type = "danger";
      formOptions.message = "Password do not match!";
      notificationAlert.current.notificationAlert(formOptions);
    }
    else {
      let data = {
        fname: user.fname,
        lname: user.lname,
        phone: user.phone,
        email: user.email,
        DOB: user.DOB,
        password: password,
      };
      try {
        const res = await axios.post(`${URL}/user/profile/update`, data);
        if (res.data) {
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          formOptions.type = "success";
          formOptions.message = "Profile Updated Successfully!";
          notificationAlert.current.notificationAlert(formOptions);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  
  return (
    <>
      <div className="content d-flex justify-content-center align-items-center">
        <NotificationAlert ref={notificationAlert} />
        <Row className="w-75 mx-auto">
          <Col md="12">
            <Card className="card-user p-5">
              <CardHeader>
                <CardTitle tag="h5">Edit Profile</CardTitle>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>Company (disabled)</label>
                        <Input
                          defaultValue="Maagon Advisors Inc."
                          disabled
                          placeholder="Company"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="6">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          Email address
                        </label>
                        <Input
                          placeholder="Email"
                          type="email"
                          defaultValue={user.email}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>First Name</label>
                        <Input
                          defaultValue={user.fname}
                          placeholder="First Name"
                          type="text"
                          onChange={(e) =>
                            setUser((prev) => ({
                              ...prev,
                              fname: e.target.value,
                            }))
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="6">
                      <FormGroup>
                        <label>Last Name</label>
                        <Input
                          defaultValue={user.lname}
                          placeholder="Last Name"
                          type="text"
                          onChange={(e) =>
                            setUser((prev) => ({
                              ...prev,
                              lname: e.target.value,
                            }))
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>New Password</label>
                        <Input
                          // defaultValue={user.fname}
                          placeholder="New Password"
                          type="text"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="6">
                      <FormGroup>
                        <label>Confirm New Password</label>
                        <Input
                          // defaultValue={user.lname}
                          placeholder="Confirm Password"
                          type="text"
                          onChange={(e) => setConfirm(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>Phone</label>
                        <Input
                          defaultValue={user.phone}
                          placeholder="913-742-0000"
                          type="tel"
                          onChange={(e) =>
                            setUser((prev) => ({
                              ...prev,
                              phone: e.target.value,
                            }))
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="6">
                      <FormGroup>
                        <label>Date of Birth</label>
                        <Input
                          defaultValue={user.DOB}
                          placeholder="Date of Birth"
                          type="date"
                          onChange={(e) =>
                            setUser((prev) => ({
                              ...prev,
                              DOB: e.target.value,
                            }))
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                      >
                        Update Profile
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Profile;
