import React, { useState } from "react";
import { useDispatch } from "react-redux";
import NotificationAlert from "react-notification-alert";
// reactstrap components
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
  Spinner,
} from "reactstrap";
import { createUser } from "../actions/userActions";

function AddUser() {
  const notificationAlert = React.useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    fname: "",
    lname: "",
    email: "",
    password: "",
    phone: "",
    DOB: "",
    type: "user",
  });

  var formOptions = {
    place: "tr",
    icon: "nc-icon nc-bell-55",
    autoDismiss: 4,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const values = Object.values(user);
    const result = values.filter((el) => el === "");

    if (result.length) {
      formOptions.message = "Fields can not be Empty!";
      notificationAlert.current.notificationAlert(formOptions);
    } else {
      const data = await dispatch(createUser(user));
      if (data.type == "success") {
        formOptions.type = "success";
        formOptions.message = data.message;
        notificationAlert.current.notificationAlert(formOptions);
        setUser((prev) => ({
          ...prev,
          fname: "",
          lname: "",
          email: "",
          password: "",
          phone: "",
          DOB: "",
          type: "user",
        }));
      } else {
        formOptions.type = "danger";
        formOptions.message = data.message;
        notificationAlert.current.notificationAlert(formOptions);
      }

      setLoading(false);
    }
  };

  return (
    <>
      <div className="content d-flex justify-content-center align-items-center">
        <NotificationAlert ref={notificationAlert} />
        <Row className="w-75 mx-auto">
          <Col md="12">
            <Card className="card-user p-5">
              <CardHeader>
                <CardTitle tag="h4">Add User</CardTitle>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleName">First Name*</Label>
                        <Input
                          required
                          type="text"
                          name="fname"
                          id="exampleName"
                          value={user.fname}
                          onChange={(e) =>
                            setUser((prev) => ({
                              ...prev,
                              fname: e.target.value,
                            }))
                          }
                          placeholder="First Name"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleLName">Last Name*</Label>
                        <Input
                          type="text"
                          name="lname"
                          id="exampleLName"
                          value={user.lname}
                          onChange={(e) =>
                            setUser((prev) => ({
                              ...prev,
                              lname: e.target.value,
                            }))
                          }
                          placeholder="Last Name"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Email*</Label>
                        <Input
                          type="email"
                          name="email"
                          id="exampleEmail"
                          value={user.email}
                          onChange={(e) =>
                            setUser((prev) => ({
                              ...prev,
                              email: e.target.value,
                            }))
                          }
                          placeholder="Email"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="examplePassword">Password*</Label>
                        <Input
                          type="password"
                          name="password"
                          id="examplePassword"
                          value={user.password}
                          onChange={(e) =>
                            setUser((prev) => ({
                              ...prev,
                              password: e.target.value,
                            }))
                          }
                          placeholder="password"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="examplePhone">Phone*</Label>
                        <Input
                          type="number"
                          name="phone"
                          id="examplePhone"
                          value={user.phone}
                          onChange={(e) =>
                            setUser((prev) => ({
                              ...prev,
                              phone: e.target.value,
                            }))
                          }
                          placeholder="Phone"
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for="exampleDate">Date of Birth*</Label>
                        <Input
                          type="date"
                          name="DOB"
                          id="exampleDate"
                          value={user.DOB}
                          onChange={(e) =>
                            setUser((prev) => ({
                              ...prev,
                              DOB: e.target.value,
                            }))
                          }
                          placeholder="City"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label for="exampleSelect">Type</Label>
                    <Input
                      type="select"
                      name="select"
                      id="exampleSelect"
                      value={user.type}
                      onChange={(e) =>
                        setUser((prev) => ({
                          ...prev,
                          type: e.target.value,
                        }))
                      }
                    >
                      <option value={"user"}>User</option>
                      <option value={"pm"}>Portal Manager</option>
                      <option value={"admin"}>Admin</option>
                    </Input>
                  </FormGroup>
                  {/* <Button color="primary" type="submit">
                    Add
                  </Button> */}
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="btn-round"
                        size="md"
                        color="primary"
                        type="submit"
                      >
                        {loading ? (
                          <Spinner animation="border" size={"sm"} />
                        ) : (
                          "Add"
                        )}
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AddUser;
