import React from "react";
import CanvasJSReact from "./canvasjs.react";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
CanvasJS.addColorSet("greenShades", [
  //colorSet Array
  "#57C478",
]);
const Chart = () => {
  const options = {
    animationEnabled: true,
    colorSet: "greenShades",
    title: {
      text: "",
    },
    axisX: {
      valueFormatString: "YY",
      interval: 2,
    },
    axisY: {
      title: "",
      prefix: "$",
      interval: 10000,
    },
    data: [
      {
        yValueFormatString: "$#,###",
        xValueFormatString: "MMMM",
        type: "spline",
        lineThickness: 5,
        markerSize: 10,
        indexLabelFontColor: "orangered",
        dataPoints: [
          { label: new Date().getFullYear()-1, y: 25060, lineColor: "#FFD86D" },
          { label: new Date().getFullYear(), y: 27980, lineColor: "#FFD86D", indexLabel: "Today", indexLabelFontFamily: "Segoe UI", indexLabelPlacement: "outside", indexLabelFontColor: "#55BF75", indexLabelTextAlign: "center", indexLabelFontWeight: "bold"  },
          { label: new Date().getFullYear()+1, y: 42800, lineColor: "#FFD86D" },
          { label: new Date().getFullYear()+2, y: 32400 },
          { label: new Date().getFullYear()+3, y: 29260, indexLabel: "Short Term Goal", indexLabelFontFamily: "Segoe UI", indexLabelPlacement: "outside", indexLabelFontColor: "#55BF75", indexLabelTextAlign: "center", indexLabelFontWeight: "bold" },
          { label: new Date().getFullYear()+4, y: 33900 },
          { label: new Date().getFullYear()+5, y: 22000 },
          { label: new Date().getFullYear()+6, y: 40900 , indexLabel: "Long Term Goal", indexLabelFontFamily: "Segoe UI", indexLabelPlacement: "outside", indexLabelFontColor: "#55BF75", indexLabelTextAlign: "center", indexLabelFontWeight: "bold" },
          { label: new Date().getFullYear()+7, y: 43260 },
        ],
      },
    ],
  };
  return (
    <div>
      <CanvasJSChart
        options={options}
        /* onRef={ref => this.chart = ref} */
      />
      {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
    </div>
  );
};

export default Chart;
