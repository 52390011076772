import axios from "axios";
import {
    DISPOSIBLE_EMAIL_RES_LIST_REQUEST,
    DISPOSIBLE_EMAIL_RES_LIST_SUCCESS,
    DISPOSIBLE_EMAIL_RES_LIST_FAIL,
    DISPOSIBLE_USER_RES_LIST_REQUEST,
    DISPOSIBLE_USER_RES_LIST_SUCCESS,
    DISPOSIBLE_USER_RES_LIST_FAIL,
    TOTAL_NETWORTH_USER_RES_LIST_REQUEST,
    TOTAL_NETWORTH_USER_RES_LIST_SUCCESS,
    TOTAL_NETWORTH_USER_RES_LIST_FAIL,
} from "../constants/userConstants";
import { URL } from "../shared/constants";

export const getDisposableIncomeByEmail = (email) => async (dispatch) => {
  try {
    dispatch({
      type: DISPOSIBLE_EMAIL_RES_LIST_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.get(`${URL}/income/get-disposable-income-by-email?email=${email}`, config);
    dispatch({
      type: DISPOSIBLE_EMAIL_RES_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DISPOSIBLE_EMAIL_RES_LIST_FAIL,
      payload: error.response.data.message,
    });
  }
};


export const createDisposable = (disposableData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      `${URL}/income/add-disposable-income`,
      disposableData,
      config
    );
    if (data) {
      return { type: "success", message: data.message };
    }
  } catch (error) {
    return { type: "error", message: error.response.data.message };
  }
};

export const updateDisposable = (disposableData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.put(
      `${URL}/income/edit-disposable-income?email=${disposableData.email}`,
      disposableData,
      config
    );
    if (data) {
      return { type: "success", message: data.message };
    }
  } catch (error) {
    return { type: "error", message: error.response.data.message };
  }
};

export const getDisposableIncomeByUser = (email) => async (dispatch) => {
  try {
    dispatch({
      type: DISPOSIBLE_USER_RES_LIST_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.get(
      `${URL}/income/get-disposable-income-by-user?email=${email}`,
      config
    );
    dispatch({
      type: DISPOSIBLE_USER_RES_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DISPOSIBLE_USER_RES_LIST_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getTotalNetWorthByUser = (email) => async (dispatch) => {
  try {
    dispatch({
      type: TOTAL_NETWORTH_USER_RES_LIST_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.get(
      `${URL}/income/get-total-networth-user?email=${email}`,
      config
    );
    dispatch({
      type: TOTAL_NETWORTH_USER_RES_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TOTAL_NETWORTH_USER_RES_LIST_FAIL,
      payload: error.response.data.message,
    });
  }
};