import React from "react";
import Logo from "../../../assets/img/logo.png";
import { TiSocialLinkedinCircular } from "react-icons/ti";
import { RiFacebookCircleLine } from "react-icons/ri";
import { TiSocialTwitterCircular } from "react-icons/ti";

const Footer = () => {
  return (
    <div className="main-footer">
      <img src={Logo} alt="magoon-logo" className="footer-magoon-logo"></img>
      <h3 className="footer-content">
      "Someone's sitting in the shade today because someone planted a tree a long time ago." <br /> - Warren Buffett
      </h3>
      <div className="social-icons">
        <a href="/" className="facebook-icon">
          <RiFacebookCircleLine />
        </a>
        <a href="/" className="twitter-icon">
          <TiSocialTwitterCircular />
        </a>
        <a href="/" className="linkedin-icon">
          <TiSocialLinkedinCircular />
        </a>
      </div>
    </div>
  );
};

export default Footer;
