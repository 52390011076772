export const URL =
  window.location.protocol === "http:"
    ? window.location.hostname === "localhost" ? "http://localhost:5000" : "http://api.maagonadvisors.com"
    : window.location.hostname === "localhost" ? "http://localhost:5000" : "https://api.maagonadvisors.com";
// export const URL = "http://3.93.149.63:5000";
export const RECAPTCHA_SITE_KEY = "6Lf8lm0hAAAAAHE8J6nqFdW0hUBEzLikmWw7crzZ";
export const GOOGLE_CLIENT_ID = "1063569267351-du3t8gsnsn804804icgh4ke4ad5oq781.apps.googleusercontent.com";
export const FACEBOOK_APP_ID = "376537931089119";
export const YODLEE_API_URL = "https://production.api.yodlee.com/ysl";
export const YODLEE_USER_REGISTER_URL = "https://production.api.yodlee.com/ysl/user/register";
export const YODLEE_FAST_LINK_URL = "https://fl4.prod.yodlee.com/authenticate/USDevexProd4-133/fastlink?channelAppName=usdevexprod4";
export const YODLEE_CLIENT_ID = "8yCnHuEEQBFLlYzffhor8NsFe67XKusT";
export const YODLEE_SECRET = "X06d9nx7K7vZ4s3E";
export const YODLEE_ADMIN_LOGINNAME = "0b38bfc6-fec0-4e6a-9f38-0605445f9532_ADMIN";

// export const YODLEE_API_URL = "https://development.api.yodlee.com/ysl"
// export const YODLEE_USER_REGISTER_URL = "https://development.api.yodlee.com/ysl/user/register";
// export const YODLEE_FAST_LINK_URL = "https://fl4.preprod.yodlee.com/authenticate/USDevexPreProd4-187/fastlink?channelAppName=usdevexpreprod4";
// export const YODLEE_CLIENT_ID = "ly2WjZ9t3SGxeFqtCZQdXanGNowr96ck";
// export const YODLEE_SECRET = "bG4EBGJNbcIUwV4a";
// export const YODLEE_ADMIN_LOGINNAME = "d4c5fc71-7ebd-4869-acff-6e2365758fe7_ADMIN";