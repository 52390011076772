import React, { useState } from "react";
import { Link } from "react-router-dom";
import goal1 from "../../../landing/assets/images/SMgoals.png";
import goal2 from "../../../landing/assets/images/retirement.png";
import goal3 from "../../../landing/assets/images/generation.png";
import eclipse1 from "../../../landing/assets/images/eclipse-wealth.png";
import eclipse2 from "../../../landing/assets/images/eclipse-texable.png";
import eclipse3 from "../../../landing/assets/images/eclipse-advantage.png";
import eclipse4 from "../../../landing/assets/images/eclipse-saving.png";
import Chart from "react-apexcharts";
import { Card } from "reactstrap";
import AppHeader from "../navbar/AppHeader";
import Footer from "../Footer/Footer";
import Qoute from "../Qoute/Qoute";

const Dream = () => {
  const [toggleGraph, setToggleGraph] = useState("wealth");
  const data = {
    series: [
      {
        name: "Wealth",
        data: [3, 8, 6, 15, 12, 33],
      },
      {
        name: "Texable Investment",
        data: [11, 32, 45, 32, 34, 53],
      },
      {
        name: "Tax-Advantage Investment",
        data: [6, 14, 30, 23, 14, 64],
      },
      {
        name: "Saving Cushion",
        data: [6, 43, 39,54, 78, 74],
      },
    ],
    options: {
      colors:["#2948FF", "#CFA925" , "#02AD88" , "#ED7E30"],
      // colors: [
      //   // this array contains different color code for each data
      //   toggleGraph === "wealth"
      //     ? "#2948FF"
      //     : toggleGraph === "Texable Investment"
      //     ? "#CFA925"
      //     : toggleGraph === "Tax-Advantage Investment"
      //     ? "#02AD88"
      //     : toggleGraph === "Saving Cushion"
      //     ? "#ED7E30"
      //     : "",
      // ],
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: ["Yr 2", "Yr 10", "Yr 15", "Yr 20", "Yr 25", "Yr 30"],
        colors: ["#AAB0BE"],
      },
      yaxis: {
        
        opposite: true,
        labels: {
          formatter: function(val, index) {
            return "$" + val.toFixed(2) + "mil";
          }
         }
      },
      legend: {
        horizontalAlign: "left",
      },
      legend: { position: "bottom", textStyle: { fontSize: 16 } },
    },
  };

  return (
    <>
      <div className="pb-5">
        <AppHeader />
      </div>
      <section className="goals py-5 mt-5">
        <div className="container">
          <div className="row mt-5">
            <h1 className="col-md-12 text-center py-4 lifetime-heading">
              Lifetime Goals
            </h1>
            <div className="col-md-12 col-lg-4 mt-3">
              <div className="goals-list p-5">
                <img src={goal1} alt="short and medium goals" />
                <h4 className="mt-3">
                  Short & Medium <br />
                  Term Goals
                </h4>
                <p>
                  Set 1~5 year goals and make budgeting and corresponding
                  investment decisions.
                </p>
                <Link to="/signup">
                  <button className="p-2 text-white btn-read">Read More</button>
                </Link>
              </div>
            </div>

            <div className="col-md-12 col-lg-4 mt-3">
              <div className="goals-list p-5">
                <img src={goal2} alt="wealth transfer" />
                <h4 className="mb-5 mt-3">Retirement Goal</h4>
                <p className="pt-2 mr-5">
                  Know with confidence when you can retire and what to expect.
                </p>
                <Link to="/signup">
                  <button className="p-2 text-white btn-read">Read More</button>
                </Link>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 mt-3">
              <div className="goals-list p-5">
                <img src={goal3} alt="wealth transfer" />
                <h4 className="mt-3">
                  Generation <br />
                  Wealth Transfer
                </h4>
                <p>
                  Avoid the pitfalls and maximize post-tax assets transferred to
                  your future generations.
                </p>
                <Link to="/signup">
                  <button className="p-2 text-white  btn-read">
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* wealth projection */}
      <section className="projection py-5">
        <h1 className="projection-heading text-center py-5">
          {toggleGraph === "wealth" ? "Wealth Projection" : toggleGraph === "Texable Investment" ? "Texable Investment" :toggleGraph === "Tax-Advantage Investment" ? "Tax-Advantaged Investment" : toggleGraph === "Saving Cushion" ? "Savings Cushion " : ""}
        </h1>

        <div className="container ">
          <Card className="py-5 px-3">
            <div id="chart">
            <Chart
                  className="mr-5"
                  options={data.options}
                  series={data.series}
                  type="area"
                  height={350}
                />
              {/* {toggleGraph === "wealth" ? (
                <Chart
                  className="mr-5"
                  options={data.options}
                  series={data.series}
                  type="area"
                  height={350}
                />
              ) : toggleGraph === "Texable Investment" ? (
                <Chart
                  className="mr-5"
                  options={data.options}
                  series={data.series}
                  type="area"
                  height={350}
                />
              ) : toggleGraph === "Tax-Advantage Investment" ? (
                <Chart
                  className="mr-5"
                  options={data.options}
                  series={data.series}
                  type="area"
                  height={350}
                />
              ) : toggleGraph === "Saving Cushion" ? (
                <Chart
                  className="mr-5"
                  options={data.options}
                  series={data.series}
                  type="area"
                  height={350}
                />
              ) : (
                ""
              )} */}
            </div>
            {/* <div className="container px-3">
              <div className="inline-buttons  mt-3 px-5">
                <div className="d-flex justify-content-between px-5">
                  <button
                    className="btn-wealth py-2 pr-2 pl-4"
                    onClick={() => {
                      setToggleGraph("wealth");
                    }}
                  >
                    <img
                      src={eclipse1}
                      alt="eclipse"
                      className="mr-2"
                      width={10}
                      height={10}
                    />{" "}
                    Wealth
                  </button>

                  <button
                    className="btn-texable py-2 pr-2 pl-4"
                    onClick={() => {
                      setToggleGraph("Texable Investment");
                    }}
                  >
                    <img
                      className="mr-2"
                      src={eclipse2}
                      alt="eclipse"
                      width={10}
                      height={10}
                    />
                    Texable Investment
                  </button>

                  <button
                    className="btn-advantage py-2 pr-2 pl-4"
                    onClick={() => {
                      setToggleGraph("Tax-Advantage Investment");
                    }}
                  >
                    <img
                      src={eclipse3}
                      alt="eclipse"
                      className="mr-2"
                      width={10}
                      height={10}
                    />
                    Tax-Advantaged Investment
                  </button>

                  <button
                    className="btn-saving py-2 pr-2 pl-4"
                    onClick={() => {
                      setToggleGraph("Saving Cushion");
                    }}
                  >
                    <img
                      src={eclipse4}
                      alt="eclipse"
                      className="mr-2"
                      width={10}
                      height={10}
                    />{" "}
                    Savings Cushion
                  </button>
                </div>
              </div>
            </div> */}
          </Card>
        </div>

        <div className="scenarios">
          <div className="container py-5">
            <div className="row mb-5">
              <div className="col-md-12 col-lg-4 mt-3 px-5">
                <h4 className="font-weight-bold">
                  Know the Wealth <br /> Potential & Expectation
                </h4>
                <p>
                  Wealth projection and breakdown to show the details of what to
                  expevt 20 or 30 years from now.
                </p>
              </div>
              <div className="col-md-12 col-lg-4 mt-3 px-5">
                <h4 className="font-weight-bold">
                  Scenario Analysis <br /> To Gain Clarity
                </h4>
                <p>
                  Perform a scenario analysis is see the impact of financial
                  decision, such as asset allocation and passive vs. active
                  investment.
                </p>
              </div>
              <div className="col-md-12 col-lg-4 mt-3 px-5">
                <h4 className="font-weight-bold">
                  Retirement Planning <br /> & Measurements
                </h4>
                <p>
                  What's your monthly income at retirement and what action now
                  to change that.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Qoute />
      <Footer />
    </>
  );
};

export default Dream;
