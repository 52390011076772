import React, { useState } from "react";
import { Link , NavLink } from "react-router-dom";
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Nav,
  NavItem, 
  Button,
  Collapse,
} from "reactstrap";
 
 
import Logo from "../../../assets/img/logo.png";

const AppHeader = () => {
  const [toggle, setToggle] = useState(false);
  const toggleHandler = () => {
    setToggle(!toggle);
  };
  return (
    <>
      <div className="nav-container">
        <div className="container-fluid">
          <Navbar expand="lg" className="app-header navbar-faint" dark >
            <NavbarBrand href="/home">
              <div className="logo">
                <img src={Logo} alt="magoon-logo"></img>
              </div>
            </NavbarBrand>
            <NavbarToggler onClick={toggleHandler} />
            <Collapse navbar isOpen={toggle}>
              <Nav navbar  className="navbar-lite">
              <NavItem >
                  <NavLink to="/home" >
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/today">Today</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/american-dream">My American Dream</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/pillar">How To Get There</NavLink>
                </NavItem>
              </Nav>
              <div className="nav-btn-box">
                <Link to="/login">
                  <Button outline className="sign-in-btn-lite">
                    Sign in
                  </Button>
                </Link>
                <Link to="/signup">
                  <Button outline className="get-start-btn-lite">
                    Get Started <br /> <span>Free Trial</span>
                  </Button>
                </Link>
              </div>
            </Collapse>
          </Navbar>
        </div>
      </div>
    </>
  );
};

export default AppHeader;
