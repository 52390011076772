import axios from "axios";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import logo from "../assets/img/logo.png";
import { URL } from "shared/constants";
//
const ForgotPass = () => {
  const notificationAlert = React.useRef();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [resetPage, setResetPage] = useState(false);

  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");

  var formOptions = {
    place: "tr",
    icon: "nc-icon nc-bell-55",
    autoDismiss: 4,
  };
  const handleForgotPassword = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (email === "") {
      formOptions.type = "danger";
      formOptions.message = "Fields can not be Empty";
      notificationAlert.current.notificationAlert(formOptions);
    } else {
      try {
        const res = await axios.post(`${URL}/auth/forgot-password`, {
          email: email,
        });
        if (res.data) {
          setResetPage(true);
          formOptions.type = "success";
          formOptions.message = res.data.message;
          notificationAlert.current.notificationAlert(formOptions);
        }
      } catch (error) {
        formOptions.message = error.response.data.message;
        formOptions.type = "danger";
        notificationAlert.current.notificationAlert(formOptions);
      }
    }
    setLoading(false);
  };

  //    Reset Password

  const handleResetPassword = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (code === "" || password === "") {
      formOptions.type = "danger";
      formOptions.message = "field can not be Empty!";
      notificationAlert.current.notificationAlert(formOptions);
    } else if (password !== rePassword) {
      formOptions.type = "danger";
      formOptions.message = "Password do not match!";
      notificationAlert.current.notificationAlert(formOptions);
    } else {
      try {
        const res = await axios.post(`${URL}/auth/reset-password`, {
          code: code,
          email: email,
          password: password,
        });
        if (res.data) {
          window.location.replace("/login");
          formOptions.message = res.data.message;
          notificationAlert.current.notificationAlert(formOptions);
        }
      } catch (error) {
        formOptions.type = "danger";
        formOptions.message = error.response.data.message;
        notificationAlert.current.notificationAlert(formOptions);
      }
    }
    setLoading(false);
  };

  return (
    <>
      {!resetPage ? (
        <div
          className="w-100 border"
          style={{ minHeight: "100vh", backgroundColor: "#f4f3ef" }}
        >
          <NotificationAlert ref={notificationAlert} />
          <Row className="mx-auto mt-5 card-Login">
            <Col>
              <div className="text-center">
                <Link to="/home">
                <img src={logo} alt="logo" className="my-5 text-center" />
                </Link>
                
              </div>
              <Card className="card-user p-4">
                <CardHeader>
                  <CardTitle tag="h5" className="text-center">
                    Forgot Password
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={handleForgotPassword}>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label htmlFor="exampleInputEmail1">
                            Email address
                          </label>
                          <Input
                            placeholder="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Link to="/login">return to Signin</Link>
                      </Col>
                    </Row>
                    <Row>
                      <div className="update ml-auto mr-auto">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                        >
                          <div className="px-4">
                            {loading ? (
                              <Spinner animation="border" size={"sm"} />
                            ) : (
                              "Send"
                            )}
                          </div>
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <div
          className="w-100 border"
          style={{ minHeight: "100vh", backgroundColor: "#f4f3ef" }}
        >
          <NotificationAlert ref={notificationAlert} />
          <Row className="mx-auto mt-5 card-Login">
            <Col>
              <div className="text-center">
                <Link to="/home">
                <img src={logo} alt="logo" className="my-5 text-center" />
                </Link> 
              </div>
              <Card className="card-user p-4">
                <CardHeader>
                  <CardTitle tag="h5" className="text-center">
                    Reset Your Password
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={handleResetPassword}>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label htmlFor="exampleInputCode">
                            Recovery Code
                          </label>
                          <Input
                            placeholder="Recovery Code"
                            type="text"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Password</label>
                          <Input
                            placeholder="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Confirm Password</label>
                          <Input
                            placeholder="Re-enter Password"
                            type="password"
                            value={rePassword}
                            onChange={(e) => setRePassword(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Link to="/login">return to Signin</Link>
                      </Col>
                    </Row>
                    <Row>
                      <div className="update ml-auto mr-auto">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                        >
                          <div className="px-4">
                            {loading ? (
                              <Spinner animation="border" size={"sm"} />
                            ) : (
                              "Reset"
                            )}
                          </div>
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};
export default ForgotPass;
