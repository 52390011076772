import React from "react";
import { Row, Col } from "reactstrap";
const Qoute = () => {
  return (
    <>
      <Row className="Ceo-qoute-row">
        <div className="CEO-Quote">
          <Col className="Ceo-qoute-col">
            <div className="text-center text-white text-justify">
              <ul className="list-unstyled text-center">
                <li>
                  <h2>Know Where You Are Standing Financially Today </h2>
                </li>
                <li>
                  <h2>Set My American Dream</h2>
                </li>
                <li>
                  <h3>Learn How To Get There</h3>
                </li>
              </ul>
            </div>
            {/* <h2 className="heading-4 text-center text-white" >
              "The most important investment <br />
              you can make is in yourself."
            </h2>
            <h2 className="heading-5 text-center text-white">Warren Buffett</h2>
            <h2 className="heading-6 text-center text-white">CEO of Berkshire Hathaway</h2> */}
          </Col>
        </div>
      </Row>
    </>
  );
};
export default Qoute;
