import React, { useState } from "react";
import Header from "../navbar/Header";
import { Row, Col, Spinner } from "reactstrap";
import Footer from "../Footer/Footer";
import { Card } from "reactstrap";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { BsTelephone, BsPerson } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { useDispatch } from "react-redux";
import NotificationAlert from "react-notification-alert";
import { registerUser } from "actions/userActions";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../../shared/constants";
import { useHistory } from "react-router";

const Signup = () => {
  const notificationAlert = React.useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [user, setUser] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    DOB: "",
  });

  var formOptions = {
    place: "tr",
    icon: "nc-icon nc-bell-55",
    autoDismiss: 4,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const values = Object.values(user);
    const result = values.filter((el) => el === "");
    if (result.length) {
      formOptions.message = "Fields can not be Empty!";
      formOptions.type = "danger";
      notificationAlert.current.notificationAlert(formOptions);
      setLoading(false);
    } else if (password !== confirm) {
      formOptions.type = "danger";
      formOptions.message = "Password do not match!";
      notificationAlert.current.notificationAlert(formOptions);
      setLoading(false);
    } else {
      let userData = {
        ...user,
        password: password,
      };
      const data = await dispatch(registerUser(userData));
      if (data.type == "success") {
        formOptions.type = "success";
        formOptions.message = data.message;
        notificationAlert.current.notificationAlert(formOptions);
        setUser((prev) => ({
          ...prev,
          fname: "",
          lname: "",
          email: "",
          password: "",
          phone: "",
          DOB: "",
        }));
        history.push("/login");
      } else {
        formOptions.type = "danger";
        formOptions.message = data.message;
        notificationAlert.current.notificationAlert(formOptions);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <Header />

      <div className="signup">
        <NotificationAlert ref={notificationAlert} />
        <div className="">
          <div className="container py-5">
            <Card className="py-5 px-2 ">
              <h2 className="text-center font-weight-bold">
                Registration Form
              </h2>
              <div>
                <Form
                  name="signup"
                  className="signup-form py-5 px-4"
                  onSubmit={handleSubmit}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <FormGroup>
                        <Label for="firstName">First Name</Label>
                        <Input
                          type="text"
                          name="firstName"
                          id="firstName"
                          value={user.fname}
                          onChange={(e) => {
                            setUser((prev) => ({
                              ...prev,
                              fname: e.target.value,
                            }));
                          }}
                          placeholder="First Name"
                        />

                        <BsPerson />
                      </FormGroup>
                    </div>
                    <div className="col-md-6">
                      <FormGroup>
                        <Label for="lastName">Last Name</Label>
                        <Input
                          type="text"
                          name="lastName"
                          id="lastName"
                          value={user.lname}
                          onChange={(e) => {
                            setUser((prev) => ({
                              ...prev,
                              lname: e.target.value,
                            }));
                          }}
                          placeholder="Last Name"
                        />
                        <BsPerson />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row py-3">
                    <div className="col-12">
                      <FormGroup>
                        <Label for="exampleEmail">Email</Label>
                        <Input
                          type="email"
                          name="email"
                          id="exampleEmail"
                          value={user.email}
                          onChange={(e) => {
                            setUser((prev) => ({
                              ...prev,
                              email: e.target.value,
                            }));
                          }}
                          placeholder="Email"
                        />
                        <HiOutlineMail />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row py-3">
                    <div className="col-md-6">
                      <FormGroup>
                        <Label for="newPassword">New Password</Label>
                        <Input
                          type="password"
                          id="newPassword"
                          //value={user.fname}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="New Password"
                        />
                        <BsPerson />
                      </FormGroup>
                    </div>
                    <div className="col-md-6">
                      <FormGroup>
                        <Label for="confirmPassword">Confirm Password</Label>
                        <Input
                          type="password"
                          id="confirmPassword"
                          onChange={(e) => setConfirm(e.target.value)}
                          placeholder="Confirm Password"
                        />
                        <BsPerson />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row py-3">
                    <div className="col-md-6">
                      <FormGroup>
                        <Label for="phnNo">Mobile Phone</Label>
                        <Input
                          type="tel"
                          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                          name="phnNo"
                          id="phnNo"
                          value={user.phone}
                          onChange={(e) => {
                            setUser((prev) => ({
                              ...prev,
                              phone: e.target.value,
                            }));
                          }}
                          placeholder="913-742-0000"
                        />
                        <BsTelephone />
                      </FormGroup>
                    </div>
                    <div className="col-md-6">
                      <FormGroup>
                        <Label for="exampleDate">Date Of Birth</Label>
                        <Input
                          type="date"
                          name="date"
                          id="exampleDate"
                          value={user.DOB}
                          onChange={(e) => {
                            setUser((prev) => ({
                              ...prev,
                              DOB: e.target.value,
                            }));
                          }}
                          placeholder="date placeholder"
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <Row className="w-100">
                    <div className="captcha">
                      <ReCAPTCHA
                        className="ml-3"
                        style={{ display: "inline-block" }}
                        sitekey={RECAPTCHA_SITE_KEY}
                        onChange={(e) => setVerified(true)}
                      />
                    </div>
                  </Row>
                  <div className="text-center mt-3">
                    <Button
                      className="btn-success"
                      disabled={!verified}
                      type="submit"
                    >
                      {loading ? (
                        <Spinner animation="border" size={"md"} />
                      ) : (
                        "Get Started"
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </Card>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Signup;
