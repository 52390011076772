import axios from "axios";
import {
  GOOGLE_FORM_RES_LIST_REQUEST,
  GOOGLE_FORM_RES_LIST_SUCCESS,
  GOOGLE_FORM_RES_LIST_FAIL,
  GOOGLE_FORM_RES_BY_ID_REQUEST,
  GOOGLE_FORM_RES_BY_ID_SUCCESS,
  GOOGLE_FORM_RES_BY_ID_FAIL,
  GOOGLE_FORM_RES_BY_EMAIL_REQUEST,
  GOOGLE_FORM_RES_BY_EMAIL_SUCCESS,
  GOOGLE_FORM_RES_BY_EMAIL_FAIL
} from "../constants/userConstants";
import { URL } from "../shared/constants";

export const getGoogleFormResList = () => async (dispatch) => {
  try {
    dispatch({
      type: GOOGLE_FORM_RES_LIST_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.get(`${URL}/budget/getGoogleFormData`, config);
    dispatch({
      type: GOOGLE_FORM_RES_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GOOGLE_FORM_RES_LIST_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getGoogleFormResById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GOOGLE_FORM_RES_BY_ID_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.get(`${URL}/budget/getGoogleFormDataById?id=${id}`, config);
    dispatch({
      type: GOOGLE_FORM_RES_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GOOGLE_FORM_RES_BY_ID_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getGoogleFormResByEmail = (email) => async (dispatch) => {
  try {
    dispatch({
      type: GOOGLE_FORM_RES_BY_EMAIL_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.get(`${URL}/budget/getGoogleFormDataByEmail?email=${email}`, config);
    dispatch({
      type: GOOGLE_FORM_RES_BY_EMAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GOOGLE_FORM_RES_BY_EMAIL_FAIL,
      payload: error.response.data.message,
    });
  }
};
