import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { gapi } from "gapi-script";
import AdminLayout from "layouts/Admin.js";
import UserLayout from "layouts/User.js";
import Login from "views/Login";
import ForgotPass from "views/ForgotPass";
import Dream from "landing/components/View/Dream";
import Main from "landing/components/View/Main";
import Today from "./landing/components/View/Today";
import Signup from "./landing/components/View/Signup";
import Pillar from "landing/components/View/Pillars";
import { GOOGLE_CLIENT_ID } from "./shared/constants";
import { logout } from "./actions/userActions";
import "../src/landing/landing.css";
import "../src/user/user.css";

const App = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: GOOGLE_CLIENT_ID,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  const PrivateAdminRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        userInfo && userInfo.isAdmin && !isExpired(userInfo.token) ? <Component {...props} /> : <Redirect to="/home" />
      }
    />
  );

  const PrivateUserRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        userInfo && !userInfo.isAdmin && !isExpired(userInfo.token) ? <Component {...props} /> : <Redirect to="/home" />
      }
    />
  );

  const isExpired = (token) => {
    const decode = JSON.parse(atob(token.split(".")[1]));
    if (decode.exp * 1000 < new Date().getTime()) {
      dispatch(logout());
      return true;
    } else {
      return false;
    }
  };

  return (
    <BrowserRouter>
      <Switch>
        <PrivateAdminRoute path="/admin" component={AdminLayout} render={(props) => <AdminLayout {...props} />} />
        <PrivateUserRoute path="/user" component={UserLayout} render={(props) => <UserLayout {...props} />} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgot-password" component={ForgotPass} />
        <Route exact path="/home" component={Main} />
        <Route exact path="/american-dream" component={Dream} />
        <Route exact path="/today" component={Today} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/pillar" component={Pillar} />
        <Redirect
          to={ userInfo && userInfo.isAdmin ? "/admin/dashboard" : userInfo && !userInfo.isAdmin ? "/user/dashboard" : "/home" }
        />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
