import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory, NavLink } from "react-router-dom";
import axios from "axios";
import NotificationAlert from "react-notification-alert";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { logout } from "../../../actions/userActions";
import Logo from "../../../assets/img/logo.png";
import joseph from "../../../../src/user/assets/images/joseph.png";
import { URL } from "../../../shared/constants";
import Avatar from "react-avatar";

function Header(props) {
  const notificationAlert = useRef();
  const [user, setUser] = useState({});
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [color, setColor] = useState("transparent");
  const history = useHistory();
  const dispatch = useDispatch();
  const sidebarToggle = useRef();
  const location = useLocation();
  const [selectedFile, setSelectedFile] = useState("");
  // const userLogin = useSelector((state) => state.userLogin);
  // const { userInfo } = userLogin;
  let formOptions = {
    place: "tr",
    icon: "nc-icon nc-bell-55",
    autoDismiss: 4,
  };

  const userInfo = localStorage.getItem("userInfo");

  useEffect(() => {
    setUser(JSON.parse(userInfo));
    console.log(JSON.parse(userInfo).isAdmin, "userInfo");
  }, [userInfo]);

  const handleLogout = () => {
    dispatch(logout());
    history.push("/login");
  };

  const toggle = () => {
    if (isOpen) {
      setColor("transparent");
    } else {
      setColor("dark");
    }
    setIsOpen(!isOpen);
  };
  const dropdownToggle = (e) => {
    setDropdownOpen(!dropdownOpen);
  };

  const updateColor = () => {
    if (window.innerWidth < 993 && isOpen) {
      setColor("dark");
    } else {
      setColor("transparent");
    }
  };
  useEffect(() => {
    window.addEventListener("resize", updateColor.bind(this));
  });
  useEffect(() => {
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      sidebarToggle.current.classList.toggle("toggled");
    }
  }, [location]);

  const handleFileChange = (e) => {
    if (e.target.files[0].size / 1024 < 2000) {
      setSelectedFile(e.target.files[0]);
    } else {
      alert("file should not more than 2 mb!");
    }
    //
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user.fname === "" || user.lname === "" || user.phone === "") {
      formOptions.type = "danger";
      formOptions.message = "Fields can not be Empty";
      notificationAlert.current.notificationAlert(formOptions);
    } else if (password !== confirmPassword) {
      formOptions.type = "danger";
      formOptions.message = "Password do not match!";
      notificationAlert.current.notificationAlert(formOptions);
    } else {
      let data = new FormData();
      data.append("fname", user.fname);
      data.append("lname", user.lname);
      data.append("phone", user.phone);
      data.append("email", user.email);
      data.append("DOB", user.DOB);
      data.append("password", user.password);
      // let data = {
      //   fname: user.fname,
      //   lname: user.lname,
      //   phone: user.phone,
      //   email: user.email,
      //   DOB: user.DOB,
      //   password: password,
      // };
      if (selectedFile) {
        data.append("image", selectedFile);
      }
      try {
        const res = await axios.post(`${URL}/user/profile/update`, data);
        if (res.data) {
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          setModal(false);
          formOptions.type = "success";
          formOptions.message = "Profile Updated Successfully!";
          notificationAlert.current.notificationAlert(formOptions);
        }
      } catch (error) {
        formOptions.type = "danger";
        formOptions.message = error.response.data.errorMessage;
        notificationAlert.current.notificationAlert(formOptions);
      }
    }
  };

  return (
    // add or remove classes depending if we are on full-screen-maps page or not
    <>
      <NotificationAlert ref={notificationAlert} />
      <Navbar
        color={
          props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "transparent"
            : color
        }
        expand="lg"
        className={
          props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "navbar-absolute fixed-top"
            : "navbar-absolute fixed-top " +
              (color === "transparent" ? "navbar-white" : "")
        }
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <NavbarBrand href="/home">
              <div className="logo">
                <img src={Logo} alt="magoon-logo"></img>
              </div>
            </NavbarBrand>
          </div>
          <NavbarToggler onClick={toggle}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar className="justify-content-end">
            <Nav navbar className="navbar-lite">
              {/* <NavItem>
                <NavLink to="/user/dashboard">Dashboard</NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink to="/user/today">Today</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/user/american-dream">My American Dream</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/user/how-to-get-there">How To Get There</NavLink>
              </NavItem>
            </Nav>
            <Nav navbar>
              <Dropdown
                nav
                isOpen={dropdownOpen}
                toggle={(e) => dropdownToggle(e)}
              >
                <DropdownToggle caret nav>
                  {/* <i className="nc-icon nc-single-02" /> */}
                  {
                    userInfo?.image ?
                    <img
                    src={JSON.parse(userInfo)?.image}
                    style={{
                      width: "4rem",
                      height: "3rem",
                      borderRadius: "50%",
                    }}
                  />:  <img
                  src={joseph}
                  style={{
                    width: "4rem",
                    height: "3rem",
                    borderRadius: "50%",
                  }}
                  />
                  }
                 
                </DropdownToggle>
                <DropdownMenu right>
                  {/* <DropdownItem onClick={()=>history.push("/admin/profile")}>
                  Profile
                </DropdownItem> */}
                  <DropdownItem onClick={() => setModal(true)}>
                    Profile
                  </DropdownItem>
                  <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Nav>
          </Collapse>
        </Container>

        <Modal isOpen={modal} centered size="lg">
          <Form onSubmit={handleSubmit}>
            <ModalHeader charCode="X">Edit Profile</ModalHeader>
            <ModalBody>
              <Row className="w-100">
                <input type="file" onChange={handleFileChange} />
                <Avatar
                  name={user.fname}
                  size="200px"
                  className="mx-auto mb-4 rounded-circle cursor-pointer"
                  type="file"
                />
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <label>Company</label>
                    <Input
                      defaultValue="Maagon Advisors Inc."
                      disabled
                      placeholder="Company"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label>Email address</label>
                    <Input
                      placeholder="Email"
                      type="email"
                      defaultValue={user.email}
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <label>First Name</label>
                    <Input
                      defaultValue={user.fname}
                      placeholder="First Name"
                      type="text"
                      onChange={(e) =>
                        setUser((prev) => ({
                          ...prev,
                          fname: e.target.value,
                        }))
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label>Last Name</label>
                    <Input
                      defaultValue={user.lname}
                      placeholder="Last Name"
                      type="text"
                      onChange={(e) =>
                        setUser((prev) => ({
                          ...prev,
                          lname: e.target.value,
                        }))
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <label>New Password</label>
                    <Input
                      placeholder="New Password"
                      type="text"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label>Confirm New Password</label>
                    <Input
                      placeholder="Confirm Password"
                      type="text"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <label>Phone</label>
                    <Input
                      defaultValue={user.phone}
                      placeholder="phone"
                      type="tel"
                      onChange={(e) =>
                        setUser((prev) => ({
                          ...prev,
                          phone: e.target.value,
                        }))
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label>Date of Birth</label>
                    <Input
                      defaultValue={user.DOB}
                      placeholder="Date of Birth"
                      type="date"
                      onChange={(e) =>
                        setUser((prev) => ({
                          ...prev,
                          DOB: e.target.value,
                        }))
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => setModal(false)}>
                Cancel
              </Button>{" "}
              &nbsp;
              <Button color="primary" type="submit">
                Update Profile
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Navbar>
    </>
  );
}

export default Header;
