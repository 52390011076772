import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotificationAlert from "react-notification-alert";
import Chart from "react-apexcharts";
import { Chart as GoogleChart } from "react-google-charts";
import axios from "axios";
import qs from "qs";
import {
  getDisposableIncomeByUser,
  getTotalNetWorthByUser,
} from "../../../actions/disposableActions";
import {
  URL,
  YODLEE_API_URL,
  YODLEE_FAST_LINK_URL,
  YODLEE_CLIENT_ID,
  YODLEE_SECRET,
} from "../../../shared/constants";
import {
  Card,
  Table,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  ModalFooter,
} from "reactstrap";

const Today = () => {
  const notificationAlert = React.useRef();
  const dispatch = useDispatch();
  const [todayData, setTodayData] = useState({});
  const currentYear = new Date().getFullYear();
  const lastYear = new Date().getFullYear() - 1;
  const [userLoginName, setUserLoginName] = useState("");
  const [modal, setModal] = useState(false);
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(null);
  const [totalNetWorthData, setTotalNetWorthData] = useState({});
  const [isRefresh, setIsRefresh] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const disposableRes = useSelector((state) => state.disposableIncomeResByUser);
  const { disposableIncomeResponses } = disposableRes;

  const totalNetWorthRes = useSelector((state) => state.totalNetWorthResByUser);
  const { totalNetWorthResponses } = totalNetWorthRes;

  // let formOptions = {
  //   place: "tr",
  //   icon: "nc-icon nc-bell-55",
  //   autoDismiss: 4,
  // };

  useEffect(() => {
    if (userInfo) {
      dispatch(getTotalNetWorthByUser(userInfo.email));
      dispatch(getDisposableIncomeByUser(userInfo.email));
    }
  }, [userInfo, dispatch]);

  useEffect(() => {
    let script = HTMLScriptElement;
    script = document.createElement("script");
    script.id = "yodlee-fastlink-script";
    script.src = "https://cdn.yodlee.com/fastlink/v4/initialize.js";
    script.async = true;
    script.defer = true;
    script.onload = () => setReady(true);
    script.onerror = () =>
      setError("Yodlee FastLink library could not be loaded!");
    document.body.appendChild(script);

    return () => {
      window.fastlink?.close();
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (totalNetWorthResponses) {
      setIsRefresh(true);
      setTotalNetWorthData(totalNetWorthResponses);
    } else {
      setIsRefresh(false);
      let totalNetWorthDefaultData = {
        totalNetWorth: 0,
        savings: 0,
        investments: 0,
        retirement: 0,
        accountHSA: 0,
        property: 0,
      };
      setTotalNetWorthData(totalNetWorthDefaultData);
    }
  }, [totalNetWorthResponses]);

  useEffect(() => {
    if (disposableIncomeResponses) {
      setTodayData(disposableIncomeResponses);
    } else {
      let todayDefaultData = {
        totalIncomeLY: 0,
        totalIncomeCY: 0,
        retirementLY: 0,
        retirementCY: 0,
        iRALY: 0,
        iRACY: 0,
        accountHSALY: 0,
        accountHSACY: 0,
        taxRateLY: 0,
        taxRateCY: 0,
        expensesRatioLY: 0,
        expensesRatioCY: 0,
        investmentRatioLY: 0,
        investmentRatioCY: 0,
        agiLY: 0,
        agiCY: 0,
        disposableIncomeLY: 0,
        disposableIncomeCY: 0,
        expenseLY: 0,
        expenseCY: 0,
        investmentLY: 0,
        investmentCY: 0,
        savingRatioLY: 0,
        savingRatioCY: 0,
        cushionLY: 0,
        cushionCY: 0,
      };
      setTodayData(todayDefaultData);
    }
  }, [disposableIncomeResponses]);

  const data = {
    series: [
      {
        //data: [21, 22, 10, 28, 16],
        data: [
          totalNetWorthData && totalNetWorthData.investments
            ? totalNetWorthData.investments
            : 0,
          totalNetWorthData && totalNetWorthData.savings
            ? totalNetWorthData.savings
            : 0,
          totalNetWorthData && totalNetWorthData.retirement
            ? totalNetWorthData.retirement
            : 0,
          totalNetWorthData && totalNetWorthData.accountHSA
            ? totalNetWorthData.accountHSA
            : 0,
          totalNetWorthData && totalNetWorthData.property
            ? totalNetWorthData.property
            : 0,
        ],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function (chart, w, e) {},
        },
      },
      yaxis: {
        labels: {
          formatter: function (val, index) {
            return "$" + val;
          },
        },
      },
      // colors: colors,
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },

      xaxis: {
        categories: [
          ["Investments"],
          ["Savings"],
          ["Retirements"],
          ["H.S.A"],
          ["Property"],
        ],
        labels: {
          style: {
            // colors: colors,
            fontSize: "10px",
          },
        },
      },
    },
  };
  const BudgetData = [
    ["Budget Details", "Values"],
    [
      "Expense Ratio",
      todayData && todayData.expensesRatioCY !== 0
        ? todayData.expensesRatioCY
        : 100,
    ],
    [
      "Investment Ratio",
      todayData && todayData.investmentRatioCY !== 0
        ? todayData.investmentRatioCY
        : 100,
    ],
    [
      "Savings Ratio",
      todayData && todayData.savingRatioCY !== 0
        ? todayData.savingRatioCY
        : 100,
    ],
  ];
  const options = {
    is3D: true,
    colors: ["#4170c1", "#a3a2a2", "#e97c30"],
    legend: { position: "bottom", textStyle: { fontSize: 16 } },
  };

  const openBudgetGoogleForm = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSd9Bhz4eA6p85h7FvoltHddO8zOyOCmdwT2RMdnBlrNZy159g/viewform",
      "_self"
    );
  };

  const openTaxConsultationForm = () => {
    window.open("https://maagoncpa.as.me/go", "_blank");
  };

  const handleConnectAccount = async (e) => {
    e.preventDefault();
    try {
      const YodleeURL = `${YODLEE_API_URL}/auth/token`;
      const bodyData = { clientId: YODLEE_CLIENT_ID, secret: YODLEE_SECRET };
      const options = {
        method: "POST",
        headers: {
          "Api-Version": "1.1",
          loginName: userInfo.email,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify(bodyData),
        url: YodleeURL,
      };
      const yodleeTokenRes = await axios(options);
      if (yodleeTokenRes.status === 201) {
        setModal(true);
        window.fastlink.open(
          {
            fastLinkURL: YODLEE_FAST_LINK_URL,
            accessToken: "Bearer " + yodleeTokenRes.data.token.accessToken,
            params: {
              configName: "Verification",
            },
            isIFrameMounted: true,
            onSuccess: async (data) => {
              try {
                const config = {
                  headers: {
                    "Api-Version": "1.1",
                    "Content-Type": "application/json",
                    Authorization:
                      "Bearer " + yodleeTokenRes.data.token.accessToken,
                  },
                };
                const yodleeAccountRes = await axios.get(
                  `${YODLEE_API_URL}/accounts?providerAccountId=${data.providerAccountId}`,
                  config
                );
                const apiBodyData = {
                  email: userInfo.email,
                  accountsData: yodleeAccountRes.data.account,
                };
                if (yodleeAccountRes.status === 200) {
                  setModal(false);
                  if (isRefresh) {
                    try {
                      const config = {
                        headers: {
                          "Content-Type": "application/json",
                        },
                      };
                      const apiRes = await axios.put(
                        `${URL}/income/update-total-networth-user?email=${userInfo.email}`,
                        apiBodyData,
                        config
                      );
                      if (apiRes.status === 200) {
                        setTotalNetWorthData(apiRes.data);
                      }
                    } catch (error) {
                      // formOptions.type = "danger";
                      // formOptions.message = error.response.data.message;
                      // notificationAlert.current.notificationAlert(formOptions);
                    }
                  } else {
                    try {
                      const config = {
                        headers: {
                          "Content-Type": "application/json",
                        },
                      };
                      const apiRes = await axios.post(
                        `${URL}/income/save-total-networth-user`,
                        apiBodyData,
                        config
                      );
                      if (apiRes.status === 201 || apiRes.status === 200) {
                        setTotalNetWorthData(apiRes.data);
                      }
                    } catch (error) {
                      // formOptions.type = "danger";
                      // formOptions.message = error.response.data.message;
                      // notificationAlert.current.notificationAlert(formOptions);
                    }
                  }
                }
              } catch (error) {
                setModal(false);
                // formOptions.type = "danger";
                // formOptions.message = error.response.data.errorMessage;
                // notificationAlert.current.notificationAlert(formOptions);
              }
            },
            onError: (data) => {
              console.log(data);
            },
            onClose: (data) => {
              setModal(false);
              console.log(data);
            },
            onEvent: (data) => {
              console.log(data);
            },
          },
          "container-fastlink"
        );
      }
    } catch (error) {
      console.log("error---------", error);
      setModal(false);
      // formOptions.type = "danger";
      // formOptions.message = error.response.data.errorMessage;
      // notificationAlert.current.notificationAlert(formOptions);
    }
  };

  console.log(todayData);

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <section className="user-shaded-section py-5">
        <section className="user-net-worth p-5">
          <div className="">
            <div className="row mt-3">
              <div className="col-md-12 col-lg-6">
                <div className="user-net-worth-insight">
                  <p className=" user-spending-txt">Know Your Standing</p>
                  <h2 className="font-weight-bold">
                    My Total Net <br /> Worth Today
                  </h2>
                  <button onClick={handleConnectAccount}>
                    {isRefresh ? "Balance Refresh!" : "Connect Account!"}
                  </button>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 mt-5">
                <div className="user-chart-table">
                  <Card className="chart px-3 py-2">
                    <h4 className="text-center font-weight-bold">Net Worth</h4>
                    <Chart
                      className=""
                      options={data.options}
                      series={data.series}
                      type="bar"
                      height={350}
                    />
                  </Card>
                  <Card className="px-3 py-3">
                    <Table>
                      <thead>
                        <tr>
                          <th>Total Networth</th>
                          <th className="fs-20">
                            $
                            {totalNetWorthData.totalNetWorth
                              ? totalNetWorthData.totalNetWorth.toLocaleString(
                                  "en-US"
                                )
                              : 0}
                          </th>
                          <th>
                            <span>100%</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Savings</td>
                          <td>
                            $
                            {totalNetWorthData.savings
                              ? totalNetWorthData.savings.toLocaleString(
                                  "en-US"
                                )
                              : 0}
                          </td>
                          <td>7%</td>
                        </tr>
                        <tr>
                          <td>Retirement</td>
                          <td>
                            $
                            {totalNetWorthData.retirement
                              ? totalNetWorthData.retirement.toLocaleString(
                                  "en-US"
                                )
                              : 0}
                          </td>
                          <td>13%</td>
                        </tr>
                        <tr>
                          <td>Investments</td>
                          <td>
                            $
                            {totalNetWorthData.investments
                              ? totalNetWorthData.investments.toLocaleString(
                                  "en-US"
                                )
                              : 0}
                          </td>
                          <td>40%</td>
                        </tr>
                        <tr>
                          <td>H.S.A account</td>
                          <td>
                            $
                            {totalNetWorthData.accountHSA
                              ? totalNetWorthData.accountHSA.toLocaleString(
                                  "en-US"
                                )
                              : 0}
                          </td>
                          <td>7%</td>
                        </tr>
                        <tr>
                          <td>Property</td>
                          <td>
                            $
                            {totalNetWorthData.property
                              ? totalNetWorthData.property.toLocaleString(
                                  "en-US"
                                )
                              : 0}
                          </td>
                          <td>33%</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="disposable px-5">
          <div className="disposable-top d-flex justify-content-between">
            <h3 className="mb-0">My Disposable Income</h3>
            <button onClick={openTaxConsultationForm}>
              Schedule A Tax Consultation
            </button>
          </div>
          <Card className="p-5 mt-4">
            <div style={{ overflowX: "auto" }}>
              <Table striped>
                <thead>
                  <tr>
                    <th className="text-capitalize">Income</th>
                    <th className="text-capitalize">Last Year</th>
                    <th className="text-capitalize">Current Year</th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                  <tr>
                    <td></td>
                    <td>{lastYear}</td>
                    <td>{currentYear}</td>
                  </tr>
                  <tr>
                    <td>Total Income</td>
                    <td>{todayData.totalIncomeLY}</td>
                    <td>{todayData.totalIncomeCY}</td>
                  </tr>
                  <tr>
                    <td>Self-employed retirement plans</td>
                    <td>{todayData.retirementLY}</td>
                    <td>{todayData.retirementCY}</td>
                  </tr>
                  <tr>
                    <td>IRA,401(k)</td>
                    <td>{todayData.iRALY}</td>
                    <td>{todayData.iRACY}</td>
                  </tr>
                  <tr>
                    <td>H.S.A account</td>
                    <td>{todayData.accountHSALY}</td>
                    <td>{todayData.accountHSACY}</td>
                  </tr>
                  <tr>
                    <td>Before Tax Income / AGI</td>
                    <td>{todayData.agiLY}</td>
                    <td>{todayData.agiCY}</td>
                  </tr>
                  <tr>
                    <td>TAX Rate</td>
                    <td>{todayData.taxRateLY}%</td>
                    <td>{todayData.taxRateCY}%</td>
                  </tr>
                  <tr>
                    <td>
                      <span color="text-danger">Disposable Income</span>
                    </td>
                    <th>{todayData.disposableIncomeLY}</th>
                    <th>{todayData.disposableIncomeCY}</th>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="border">
              <p className="text-center mb-0 py-3 px-3">
                Note: Speak to a tax consultant before activating your tax and
                disposable income detail.
              </p>
            </div>
          </Card>
        </section>

        <section className="budget px-5">
          <div className="budget-top d-flex justify-content-between">
            <h3 className="mb-0">My Budget</h3>
            <button onClick={openBudgetGoogleForm}>
              Click here to enter your budget info
            </button>
          </div>
          <Card className="p-5 mt-4">
            <GoogleChart
              chartType="PieChart"
              data={BudgetData}
              options={options}
              width={"100%"}
              height={"700px"}
            />
            <div style={{ overflowX: "auto" }}>
              <Table striped>
                <thead>
                  <tr>
                    <th className="text-capitalize">Budget Details</th>
                    <th className="text-capitalize">Last Year</th>
                    <th className="text-capitalize">Current Year</th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                  <tr>
                    <td></td>
                    <td>2021</td>
                    <td>2022</td>
                  </tr>
                  <tr>
                    <td>Expenses</td>
                    <td>{todayData.expenseLY}</td>
                    <td>{todayData.expenseCY}</td>
                  </tr>
                  <tr>
                    <td>Expenses ratio</td>
                    <td>{todayData.expensesRatioLY}%</td>
                    <td>{todayData.expensesRatioCY}%</td>
                  </tr>
                  <tr>
                    <td>Investment</td>
                    <td>{todayData.investmentLY}</td>
                    <td>{todayData.investmentCY}</td>
                  </tr>
                  <tr>
                    <td>Investment ratio</td>
                    <td>{todayData.investmentRatioLY}%</td>
                    <td>{todayData.investmentRatioCY}%</td>
                  </tr>
                  <tr>
                    <td>Cushion</td>
                    <td>{todayData.cushionLY}</td>
                    <td>{todayData.cushionCY}</td>
                  </tr>
                  <tr>
                    <td>
                      saving ratio
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </td>
                    <td>{todayData.savingRatioLY}%</td>
                    <td>{todayData.savingRatioCY}%</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="border">
              <p className="text-center mb-0 py-3 px-3">
                Note: Click the Button to enter and activate your budget
                projection.
              </p>
            </div>
          </Card>
        </section>
      </section>
      <Modal isOpen={modal} centered>
        {/* <Form onSubmit={handleConnectAccount}> */}
        <ModalHeader charCode="X">Connect with Yodlee's</ModalHeader>
        <ModalBody>
          {/* <FormGroup>
              <Label for="userLoginName">User Login Name</Label>
              <Input
                type="text"
                name="userLoginName"
                defaultValue={userLoginName}
                onChange={(e) => setUserLoginName(e.target.value)}
                placeholder="User Login Name"
              /> */}
          {/* </FormGroup> */}
          <div id="container-fastlink"></div>
        </ModalBody>
        {/* <ModalFooter>
            <Button color="secondary" onClick={() => setModal(false)}>
              Cancel
            </Button>{" "}
            &nbsp;
            <Button color="primary" type="submit">
              Connect Account
            </Button>
          </ModalFooter> */}
        {/* </Form> */}
      </Modal>
    </>
  );
};
export default Today;
