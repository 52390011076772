import axios from "axios";
import qs from "qs";
import {
  // USER_DETAILS_RESET,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  // USER_LIST_FAIL,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  // USER_CREATE_REQUEST,
  // USER_CREATE_SUCCESS,
  // USER_CREATE_FAIL,
  // USER_UPDATE_REQUEST,
  // USER_UPDATE_SUCCESS,
  // USER_UPDATE_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL
} from "../constants/userConstants";

import { URL, YODLEE_API_URL, YODLEE_CLIENT_ID, YODLEE_SECRET, YODLEE_ADMIN_LOGINNAME } from "../shared/constants";
import store from "store";

//  Login User
export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${URL}/auth/login`,
      {
        email: email,
        password: password,
      },
      config
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response.data.message,
    });
  }
};
export const registerUser = (userData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        // 'Authorization': token, 
      },
    };
    const { data } = await axios.post(
      `${URL}/auth/register`,
      userData,
      config
    );
    if (data) {
      // store.dispatch(userList());

      const YodleeURL = `${YODLEE_API_URL}/auth/token`;
      const bodyData = { clientId: YODLEE_CLIENT_ID, secret: YODLEE_SECRET };
      const options = {
        method: "POST",
        headers: {
          "Api-Version": "1.1",
          loginName: YODLEE_ADMIN_LOGINNAME,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify(bodyData),
        url: YodleeURL,
      };
      const yodleeTokenRes = await axios(options);
      
      const YodleeREGURL = "https://development.api.yodlee.com/ysl/user/register";

      const bodyData2 = {
        user: {
          loginName: String(data.user)
        }
      }
      
      const optionss = {
        method: "POST",
        headers: {
          "Api-Version": "1.1",
          // loginName: userLoginName,
          "Content-Type": "application/json",
          "Authorization": "Bearer " + yodleeTokenRes.data.token.accessToken,

        },
        data: bodyData2,
        url: YodleeREGURL,
      };
      await axios(optionss);
    
      return { type: "success", message: data.message };
    }
  } catch (error) {
    return { type: "error", message: error.response.data.message };
  }
};
//   user create by Admin

export const createUser = (userData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${URL}/user/create-user`,
      userData,
      config
    );
    if (data) {
      store.dispatch(userList());
      return { type: "success", message: data.message };
    }
  } catch (error) {
    return { type: "error", message: error.response.data.message };
  }
};
//   Logout

export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: USER_LOGOUT });
  // dispatch({ type: USER_DETAILS_RESET });
  // document.location.href = "/login";
};

//   User List
export const userList = () => async (dispatch) => {
  try {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    dispatch({
      type: USER_LIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${URL}/user/get-users`,
      { params: { id: userInfo.id } },
      config
    );

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response.data.message,
    });
  }
};

// deleteUser
export const deleteUser = (id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios
      .delete(`${URL}/user/deleteUser`, { params: { id: id } }, config)
      .then((e) => {
        store.dispatch(userList());
      });
  } catch (error) {
    console.log(error.response.data.message);
  }
};

// undelete user

export const unDeleteUser = (id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios
      .post(`${URL}/user/unDeleteUser`, { id: id }, config)
      .then((e) => {
        store.dispatch(userList());
      });
  } catch (error) {
    console.log(error);
  }
};

//    update user

export const updateUser = (userData, id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.put(
      `${URL}/user/updateUser?id=${id}`,
      userData,
      config
    );
    if (data) {
      store.dispatch(userList());
      return data;
    }
  } catch (error) {
    return error.response.data.message;
  }
};


export const loginCheckCode = (loginCode) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${URL}/auth/login-code-check?code=${loginCode}`, config);

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response.data.message,
    });
  }
};